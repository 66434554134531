import { gql } from "urql";

export const entityDataQuery = gql`
  query($isActive: Boolean!) {
    entitiesData(isActive: $isActive) {
      records{
        name
        dataURI
        address
        isActive
        walletAddress
        entityOnboarded
      }
      count
    }
  }
`;

export const entityAdminWhitelistedQuery = gql`
query ($entity: String!){
  entityAdminGrantedEvents(entity: $entity)  {
    records {
      entity
      entityAdmin
    },
    count
  }
} 
`;