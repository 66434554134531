import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormGroup, Grid, InputAdornment, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import { NameTextInput } from '../../../../components/TextInput/NameTextInput/NameTextInput';
import { DescriptionTextInput } from '../../../../components/TextInput/DescriptionTextInput/DescriptionTextInput';
import { CustomCheckbox } from '../../../../components/CheckBox/CheckBox';
import { PriceInput } from '../../../../components/TextInput/NumberInput/PriceInput';
import { CountInput } from '../../../../components/TextInput/NumberInput/CountInput';
import { SelectInput } from '../../../../components/Dropdown/SelectDropdown/SelectInputDropdown';
import { ImageUploadCard } from '../../components/ImageUploadCard/ImageUploadCard';
import { ICollectibleDetail } from '../../../../interfaces/ICollection.interface';
import { useAppSelector, useWeb3Context } from '../../../../hooks';
import { CatalogTypes } from '../../../../enums/catalog.enum';
import { CustomButton } from '../../../../components/Button/CustomButton';
import { defaultTheme } from '../../../../styles';
import { blobIpfsUpload, fileIpfsUpload, jsonIpfsUpload } from '../../../../services/ipfsUpload.service';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { AddCollection, AddSubscriptionPassport, CreateCollection, CreateCouponCollection, SetMarketPlaceTradability, UpdateCollectiblesdataURI, UpdateCollectionData, delinkCollection, getCollectibleDetails, linkCollection, retireCollection, toggleCollectionSociaMedialAccess, updatePassportData, updatePassportSocialData } from '../../../../slices/PassportSlice';
import { NETWORKS, ZERO_ADDRESS, getAnynetStaticProvider, isProduction } from '../../../../constants';
import { checkCollectionDataModified } from '../../../../services/collection.service';
import { updateCollectibleSocialData, updateFeaturedOfferData, updateDigitalCollectionData, updatePremiumChatData, updateOfferSocialData } from '../../../../slices/OfferSlice';
import { getIpfsCID, wait } from '../../../../helpers/ipfs';
import { ConfirmModal } from '../../../../components/ModalComponents/ConfirmModal';
import { BasicTextInput } from '../../../../components/TextInput/BasicTextInput/BasicTextInput';
import { Wallet, ethers } from 'ethers';
import { LogToConsoleError } from '../../../../helpers/Logger';
import Loot8 from "../../../../assets/images/logos/Loot8Token.png"
import { OfferType, PassportType } from '../../../../enums/offers.enum';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import './CollectionFormContainer.scss'
import { ExpandMore } from '@mui/icons-material';
import EditCollectibleModal from '../EditCollectibleModal/EditCollectibleModal';
import { useAlert } from '../../../../hooks/Alert';
import { CustomSwitch } from '../../../../components/CheckBox/CustomSwitch';
import { CollectionType } from '../../../../enums/collection.enum';
import { compressImage } from '../../../../services/imageHelper.service';
import { CollectionTransfer } from '../../../../common/collectionTransfer';
import { useCollectionTransfer } from '../../../../common/collectionTransfer/useCollectionTransfer';
import { ErrorText } from '../../../../components/Error/ErrorText';
import { updatePrivateChats } from '../../../../slices/PrivateChatSlice';
import { useLocation } from 'react-router-dom';
import { Loot8UniformCollection__factory } from '../../../../typechain';
import { EventTickets } from '../EventTicket';
import { AddCollaborators, AddGatekeepers, CreateEvent, loadEventDetails, RemoveCollaborator, RemoveEvent, RemoveGateKeepers, UpdateEvent, updateEventsData, updateRemovedEvent } from '../../../../slices/EventSlice';


const GridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  background: '#FFFFFF14 0% 0% no-repeat padding-box',
  borderRadius: 6

}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} sx={{ color: '#F4B540' }} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: "Poppins",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const infoText = "Enabling tradability will allow the collection to be tradable in the market place. Note: Once enabled, market place tradability cannot be disabled again.";
const limitedInfoText = "Setting a limit will result in the subscription being closed when the specified number is reached."
export const socialMediaText = "Kindly be aware that disabling social media will result in the removal of the social media feature from the EXPass within the patron App, and any previously posted messages will no longer be visible."

interface Props {
  collectionData: ICollectibleDetail;
  firstCard?: any;
  isFormForNewPassport?: boolean;
  type: CatalogTypes,
  setLoading: ({ open, message }: { open: boolean, message: string }) => void;
  setSelectedCard: (collection: ICollectibleDetail) => void;
  setIsFormForNewPassport: (item: boolean) => void
  allTheLinkedCollectionsInChats: Array<any> | null;
  setIsCollectibleModalOpen: (value: boolean) => void;
  setIsTransferCollection: (item: boolean) => void;
  setShowTicketCreation:(item:boolean)=>void;
  showTicketCreation:boolean;
  isTransferCollection: boolean;
  defaultCollection: any;
  wallet: Wallet;
  isCollectibleModalOpen: boolean;
  showActive: boolean;
  allCollectionData: ICollectibleDetail[];
}

export const CollectionFormContainer = ({ allTheLinkedCollectionsInChats,
  setIsFormForNewPassport, isFormForNewPassport,
  setShowTicketCreation,
  showTicketCreation,
  defaultCollection,
  showActive,
  wallet,
  firstCard, collectionData, type, setLoading,
  setSelectedCard, isCollectibleModalOpen,
  setIsCollectibleModalOpen, allCollectionData }: Props) => {

  const { address, networkId, staticProvider } = useWeb3Context();
  const dispatch = useDispatch<AppDispatch>();


  const [formInput, setFormInput] = useState(collectionData);
  const [backgroundImage, setBackgroundImage] = useState(collectionData?.imageProps?.image);
  const [ticketFormInput,setTicketFormInput] = useState({});
  // const [thumbnailImage, setThumbnailImage] = useState(collectionData?.imageProps?.thumbnailImage);
  // const [optimizedImage, setOptimizedImage] = useState(collectionData?.imageProps?.optimizedImage);


  const [backgroundVideo, setBackgroundVideo] = useState(collectionData?.video);

  const [gpsChecked, setGpsChecked] = useState<boolean>(collectionData.area.latitude && collectionData.area.longitude && collectionData.area.radius ? true : false);
  const [limitedCountChecked, setLimitedCountChecked] = useState<boolean>(collectionData.maxMint && collectionData.maxPurchase ? true : false);
  const [maxBalanceChecked, setMaxBalanceChecked] = useState(collectionData.maxBalance !== 0);
  const [tierCollection, setTierCollection] = useState(formInput.couponPoapAddress ?? "");
  const [maxTokens, setMaxTokens] = useState(formInput.couponMaxTokens ?? 0);
  const [doInviteCollaborators,setDoInviteCollaborators] = useState<boolean>(false)
  const [isDisabledModal, setIsDisabledModal] = useState<boolean>(false);
  const [category, setCategory] = useState(0);
  const allUserData = useAppSelector(state=>state.AppUser.AllUsersData);
  const userDetails = useAppSelector(state=>state.AppUser.UserData);

  const entityDetails = useAppSelector(state => state.Entity.EntityData);
  const isSuperAdmin = useAppSelector(state => state.Entity.isSuperAdmin);
  const location = useLocation();


  const isEntityOnboarded = entityDetails?.entityOnboarded;
  const availablePassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails) ?? [];
  const availableCollectibleDetails = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails)?.filter(obj => (!obj?.animationUrl || obj?.animationUrl === "")) ?? [];
  const entityAddress = useAppSelector(state => state.Entity.EntityAddress);
  const [errorStates, setErrorStates] = useState<{ entityAdminAddress: string, entityAddress: string, linkedCollectionError: string, disableError: string }>({
    entityAddress: "",
    entityAdminAddress: "",
    linkedCollectionError: "",
    disableError: "",
  });

  const resetError = () => {
    setErrorStates({
      disableError: "",
      entityAddress: "",
      entityAdminAddress: "",
      linkedCollectionError: ""
    })

  }

  const { ...collectionTransferProps } = useCollectionTransfer({
    type,
    setErrorStates,
    formInput,
    errorStates,
    resetError, setLoading,
    setSelectedCard,
  })


  useEffect(() => {
    let timeOut = setTimeout(() => {
      resetError()
    }, 3000)

    return () => { clearTimeout(timeOut) }
  }, [errorStates])


  const isPremiumExPass = useMemo(() => {
    if (type == CatalogTypes.EXPASSES && entityDetails?.entityOnboarded) {
      if (allCollectionData?.filter(i => i.isActive).length == 0) {
        return false;
      } else {
        return true
      }
    }
    if (isSuperAdmin && Number(formInput?.price) != 0 && type == CatalogTypes?.EXPASSES) {
      return true
    }
    return false;
  }, [type, allCollectionData, formInput])



  // confirm pop
  const [showConfirmPopup, setShowConfirmPopup] = useState({ confirm: () => { }, state: false, message: "" });

  const customType = (type === CatalogTypes.EXPASSES) ? "ExPass" : type;

  const { setShowToastError } = useAlert();

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const onModalCancel = () => {
    if (isDisabledModal) {
      setIsDisabledModal(false)
    }
    setShowConfirmPopup({ confirm: () => { }, state: false, message: "" });
  }

  const onModalClose = (isSubmit) => {
    if (!isSubmit) onCancel();
    setIsCollectibleModalOpen(false);
  }
  const onModalOpen = () => {
    setIsFormForNewPassport(false)
    setIsCollectibleModalOpen(true)
  }

  useEffect(() => {
    setFormInput(collectionData);
    setBackgroundImage(collectionData?.imageProps?.image);
    // setThumbnailImage(collectionData?.imageProps?.thumbnailImage);
    // setOptimizedImage(collectionData?.imageProps?.optimizedImage);
    setBackgroundVideo(collectionData.video);
    setGpsChecked(collectionData.area.latitude && collectionData.area.longitude && collectionData.area.radius ? true : false);
    setLimitedCountChecked(collectionData.maxMint && collectionData.maxPurchase ? true : false);
    setMaxBalanceChecked(collectionData.maxBalance !== 0);
    setMaxTokens(collectionData.couponMaxTokens ?? 0);
    setTierCollection(collectionData.couponPoapAddress ?? "");
  }, [collectionData]);


  const onCancel = () => {
    setFormInput(collectionData);
    setBackgroundImage(collectionData?.imageProps?.image);
    // setThumbnailImage(collectionData?.imageProps?.thumbnailImage);
    // setOptimizedImage(collectionData?.imageProps?.optimizedImage);
    setBackgroundVideo(collectionData.video);

    setGpsChecked(collectionData.area.latitude && collectionData.area.longitude && collectionData.area.radius ? true : false);
    setLimitedCountChecked(collectionData.maxMint && collectionData.maxPurchase ? true : false);
    setMaxBalanceChecked(collectionData.maxBalance !== 0);
    setMaxTokens(collectionData.couponMaxTokens ?? 0);
    setTierCollection(collectionData.couponPoapAddress ?? "");

    if (isFormForNewPassport) {
      setSelectedCard(firstCard);
      setIsFormForNewPassport(false);
    }
  }

  // console.log({collectionData});


  const onFormCreation = async (ipfsDataURI) => {
    try{
    setLoading({ open: true, message: `Creating ${customType}` });
    // let body = {...formInput};
    // if(type!=CatalogTypes?.EXPASSES){
    //   delete body.category;
    //   delete body.isPremium;
    // }


    if(type==CatalogTypes.EVENT){
        const collectionAddress = await dispatch(CreateEvent({ networkID: networkId,address: address,wallet:wallet,eventData:formInput,dataURI:ipfsDataURI,EntityAddress: entityAddress,chainID:formInput?.chain }));
        if(collectionAddress?.payload==ZERO_ADDRESS){
      setLoading({ open: false, message: `` });
      setShowToastError({ open: true, message: `${customType} creation error` });
      return
        }
        return collectionAddress?.payload
    }

    else{

    const data = formInput.isCoupon ?
      (await dispatch(CreateCouponCollection({
        networkID: networkId, provider: staticProvider, address: address, EntityAddress: entityAddress, collectibleData: formInput, dataURI: ipfsDataURI, wallet: wallet, tierPoapCollection: tierCollection, maxTokens: maxTokens, chainID: formInput?.chain
      })))
      :
      (await dispatch(CreateCollection({
        networkID: networkId, provider: staticProvider, address: address, EntityAddress: entityAddress, collectibleData: formInput, dataURI: ipfsDataURI, wallet, chainID: formInput?.chain
      })));


    if (data.payload && data.payload !== ZERO_ADDRESS) {
      let collectionAddress = data.payload;
      // setLoading({ open: true, message: `Adding ${type} to manager` });

      await dispatch(AddCollection({ networkID: networkId, provider: staticProvider, address: address, EntityAddress: entityAddress, category: category && type == CatalogTypes?.EXPASSES ? category : 0, isPremium: isPremiumExPass, collectibleAddress: collectionAddress, collectibleData: formInput, wallet, chainID: formInput?.chain }));

      if (formInput.passportType === PassportType.SUBSCRIPTON) {
        // Adding subscrition

        const floorPrice = ethers.utils.parseUnits((!isPremiumExPass && type == CatalogTypes?.EXPASSES) ? "0.00" : formInput?.price?.toString(), 18).toString();
        await dispatch(AddSubscriptionPassport({ networkID: networkId, provider: staticProvider, address: address, collectibleAddress: collectionAddress, wallet, floorPrice: floorPrice, priceRate: Number(formInput?.priceRate) }));
        // Setting MarketPlace tradability
        // await dispatch(SetMarketPlaceTradability({ networkID: networkId, provider: staticProvider, address: address, collectibleAddress : collectionAddress, wallet, marketPlaceConfig: formInput?.marketPlaceConfig }));

      }
      return collectionAddress;
    }
    else {
      setLoading({ open: false, message: "" });
      LogToConsoleError("creation Error");
      setShowToastError({ open: true, message: `${customType} creation error` });
    }
    return "";
    }
  }catch(err){
    LogToConsoleError("creation Error");

  }
  }

  // console.log({formInput});

  const onSubmitHandler = async (event) => {
    event?.preventDefault();
    if (JSON.stringify(collectionData) === JSON.stringify(formInput) &&
      !(formInput.address !== "" && !collectionData.subscriptionSet && formInput.passportType === PassportType.SUBSCRIPTON)
    ) return;
    // onModalClose(true);
    onModalCancel();
    let collectionAddress = formInput.address;

    try {

      if (collectionAddress === "") {

        let ipfsDataURI = await ipfsDataGeneration();

        if (ipfsDataURI && ipfsDataURI !== "") {
          collectionAddress = await onFormCreation(ipfsDataURI);
          if (collectionAddress === "") return;
        }
        else {
          setLoading({ open: false, message: "" });
          setShowToastError({ open: true, message: "Image upload error" });
          LogToConsoleError("IPFS Upload error");
          return;
        }
      }
      else {
        await onFormUpdation(collectionAddress);

      }
      if((formInput?.getKeepers != collectionData?.getKeepers) && type==CatalogTypes.EVENT){
        setLoading({ open: true, message: `Adding/Removing GateKeepers to event` });
        
          let oldLinks = collectionData?.getKeepers;
          let newLinks = formInput?.getKeepers;
  
          let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a) === -1);
  
          if (delinks && delinks.length > 0) {
            for (const getKeepers of delinks) {
              try {
          await dispatch(RemoveGateKeepers({contractAddress:collectionAddress,chainID:formInput?.chain,networkID:networkId,address,getKeeperAddress:getKeepers,wallet,EntityAddress:entityAddress}))
               
              } catch (error) {
                console.error('Error with collectible:', getKeepers, error);
              }
            }
          }
          let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a) === -1);
          if ((Array.isArray(addlinks) && addlinks?.length > 0)) {
            try{

          await dispatch(AddGatekeepers({contractAddress:collectionAddress,chainID:formInput?.chain,networkID:networkId,address,addresses:formInput?.getKeepers,wallet,EntityAddress:entityAddress}))
            }catch(err){
              LogToConsoleError(err);
            }
        }
      }
      if((formInput?.collaborators != collectionData?.collaborators) && type==CatalogTypes.EVENT){
        setLoading({ open: true, message: `Adding collaborators to event` });
        
          let oldLinks = collectionData?.collaborators;
          let newLinks = formInput?.collaborators;
  
          let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a) === -1);
  
          if (delinks && delinks.length > 0) {
            for (const collaborators of delinks) {
              try {
          await dispatch(RemoveCollaborator({contractAddress:collectionAddress,chainID:formInput?.chain,networkID:networkId,address,collabAddress:collaborators,wallet,EntityAddress:entityAddress}))
               
              } catch (error) {
                console.error('Error with collectible:', collaborators, error);
              }
            }
          }
          let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a) === -1);
          if ((Array.isArray(addlinks) && addlinks?.length > 0)) {
            try{
          await dispatch(AddCollaborators({contractAddress:collectionAddress,chainID:formInput?.chain,networkID:networkId,address,addresses:formInput?.collaborators,wallet,EntityAddress:entityAddress}))
          }catch(err){
            LogToConsoleError(err)
          }
          }
      }

      if(formInput?.linkedTicketFeaturedOffers !== collectionData?.linkedTicketFeaturedOffers){
        setLoading({ open: true, message: `Linking/Delinking collections` });
        let oldLinks = collectionData?.linkedTicketFeaturedOffers;
        let newLinks = formInput?.linkedTicketFeaturedOffers;
        let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a) === -1);
        let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a) === -1);
        const collectionAddress = formInput?.eventTickets?.map(i=>i)[0];

        if (delinks && delinks.length > 0) {
          for (const collectible of delinks) {
            try {
              await dispatch(delinkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, collectibleAddressB: collectible, wallet }));
            } catch (error) {
              console.error('Error with collectible:', collectible, error);
            }
          }
        }

        if ((Array.isArray(addlinks) && addlinks?.length > 0)) {
          try{
          await dispatch(linkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, linkingAddresses: addlinks, wallet }));
         
        }catch(err){
          LogToConsoleError(err)
        }
        }
      }

      if(formInput?.linkedTicketCollectibles !== collectionData?.linkedTicketCollectibles){
        setLoading({ open: true, message: `Linking/Delinking collections` });
        let oldLinks = collectionData?.linkedTicketCollectibles;
        let newLinks = formInput?.linkedTicketCollectibles;
        let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a) === -1);
        let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a) === -1);
        const collectionAddress = formInput?.eventTickets?.map(i=>i)[0];

        if (delinks && delinks.length > 0) {
          for (const collectible of delinks) {
            try {
              await dispatch(delinkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, collectibleAddressB: collectible, wallet }));
            } catch (error) {
              console.error('Error with collectible:', collectible, error);
            }
          }
        }

        if ((Array.isArray(addlinks) && addlinks?.length > 0)) {
          try{
          await dispatch(linkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, linkingAddresses: addlinks, wallet }));
         
        }catch(err){
          LogToConsoleError(err)
        }
        }
      }

      if (formInput?.linkCollectible !== collectionData?.linkCollectible) {
        setLoading({ open: true, message: `Linking/Delinking collections` });

        let oldLinks = collectionData?.linkCollectible;
        let newLinks = formInput?.linkCollectible;
      
        let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a) === -1);
        let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a) === -1);
              
        if (delinks && delinks.length > 0) {
          for (const collectible of delinks) {
            try {
              await dispatch(delinkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, collectibleAddressB: collectible, wallet }));
            } catch (error) {
              console.error('Error with collectible:', collectible, error);
            }
          }
        }

        if ((Array.isArray(addlinks) && addlinks?.length > 0)) {
          try{
          await dispatch(linkCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, linkingAddresses: addlinks, wallet }));
         
        }catch(err){
          LogToConsoleError(err)
        }
        }

      }
  
    if (formInput.address === "") setSelectedCard({ ...collectionData, address: collectionAddress });
    }
    catch (e) {
      LogToConsoleError(e);
    }
    await wait(100);

    setLoading({ open: true, message: "Fetching updated details" });
    
    await loadDetails(collectionAddress, formInput.chain);
    setIsCollectibleModalOpen(false)
    setLoading({ open: false, message: "" });
    setSelectedCard(defaultCollection)
  }


  const onCreationSwitch = (event: any) => {
    event?.preventDefault();
    // onModalClose(true);
    if(type==CatalogTypes.EVENT && ((isSuperAdmin && !userDetails?.isOnboarded) || (!isSuperAdmin && userDetails?.isOnboarded))){
      setShowToastError({ open: true, message: "Onboarded user with manager access is allowed to create an event" });
      return 
    }
    setShowConfirmPopup({
      confirm: () => onSubmitHandler(event), state: true,
      message: "Is everything set for the collection to go live? "
      // `Are you sure, the ${customType} will be deployed in ${NETWORKS[formInput?.chain].chainName}?` 
    });
  }

  const onFormUpdation = async (collectionAddress) => {
    // updating passport
    let ipfsDataURI=formInput?.dataURI;
    if (((formInput?.name?.trim() !== collectionData?.name?.trim()) || (formInput?.details?.trim() !== collectionData?.details?.trim()) || (formInput?.imageProps?.image !== collectionData?.imageProps?.image)
      || (formInput?.imageProps?.thumbnailImage !== collectionData?.imageProps?.thumbnailImage)
      || (formInput?.imageProps?.optimizedImage !== collectionData?.imageProps?.optimizedImage)
      || (formInput?.video !== collectionData?.video) || (formInput?.animationUrl !== collectionData?.animationUrl))
      || (((formInput?.socialLinks?.discord !== collectionData?.socialLinks?.discord) || (formInput?.socialLinks?.telegram !== collectionData?.socialLinks?.telegram)
        || (formInput?.socialLinks?.facebook !== collectionData?.socialLinks?.facebook) ||
        (formInput?.socialLinks?.twitter !== collectionData?.socialLinks?.twitter) ||
        (formInput?.socialLinks?.instagram !== collectionData?.socialLinks?.instagram) ||
        (formInput?.socialLinks?.tiktok !== collectionData?.socialLinks?.tiktok)
        // || (formInput?.isPremium!==collectionData?.isPremium)
      ))) {

      ipfsDataURI = await ipfsDataGeneration();
      setLoading({ open: true, message: `Updating ${customType} details` });
      if(type==CatalogTypes.EVENT){
      await dispatch(UpdateEvent({ networkID: networkId,address: address,wallet:wallet,eventData:formInput,dataURI:ipfsDataURI,EntityAddress: entityAddress,chainID:formInput?.chain }));
      }else{
      await dispatch(UpdateCollectiblesdataURI({ networkID: networkId, provider: staticProvider, address, collectibleAddress: collectionData?.address, wallet, dataURI: ipfsDataURI, chainID: formInput.chain }));
      }
    }

    if (checkCollectionDataModified(formInput, collectionData)) {
      setLoading({ open: true, message: `Updating ${customType} details` });
      if(type==CatalogTypes.EVENT){
      await dispatch(UpdateEvent({ networkID: networkId,address: address,wallet:wallet,eventData:formInput,dataURI:ipfsDataURI,EntityAddress: entityAddress,chainID:formInput?.chain }));
      }
      else{
        await dispatch(UpdateCollectionData({ networkID: networkId, provider: staticProvider, address, isPremium: formInput?.isPremium ? true : false, category: category ? category : 0, EntityAddress: entityAddress, collectibleAddress: collectionAddress, collectibleData: formInput, wallet }));
      }
    }
    // Setting MarketPlace tradability
    if ((collectionData.collectionType === CollectionType.PASSPORT || collectionData.collectionType === CollectionType.COLLECTION) && formInput?.marketPlaceConfig && collectionData?.marketPlaceConfig?.publicTradeAllowed !== formInput?.marketPlaceConfig?.publicTradeAllowed) {
      await dispatch(SetMarketPlaceTradability({ networkID: networkId, provider: staticProvider, address: address, collectibleAddress: collectionAddress, wallet, marketPlaceConfig: formInput?.marketPlaceConfig }));
    }
    if (!collectionData.subscriptionSet && formInput.passportType === PassportType.SUBSCRIPTON) {
      setLoading({ open: true, message: `Updating ${customType} details` });
      const floorPrice = ethers.utils.parseUnits(formInput?.price?.toString(), 18).toString();
      await dispatch(AddSubscriptionPassport({ networkID: networkId, provider: staticProvider, address: address, collectibleAddress: collectionAddress, wallet, floorPrice: floorPrice, priceRate: Number(formInput?.priceRate) }));
    }
  }


  const ipfsDataGeneration = async () => {

    setLoading({ open: true, message: formInput?.isVideoCollectible ? "Uploading Video" : "Uploading Image" });

    let collectionImageIpfsUri = "", collectionVideoIpfsUri = "";
    const originalImage: any = formInput?.imageProps?.image ? formInput?.imageProps?.image : ""
    const originalImagePath = originalImage?.size ? "" : originalImage;
    const imageThumbnail: any = formInput?.imageProps?.thumbnailImage ? formInput?.imageProps?.thumbnailImage : ""
    const imageThumbnailPath = imageThumbnail?.name ? "" : imageThumbnail;
    const optimizedImage: any = formInput?.imageProps?.optimizedImage ? formInput?.imageProps?.optimizedImage : ""
    const optimizedImagePath = optimizedImage?.size ? "" : optimizedImage;


    let collectionImageThumbnail = { uri: getIpfsCID(imageThumbnailPath), imageSize: formInput.imageProps?.thumbnailImageSize ?? 0 },
      collectionImageOptimized = { uri: getIpfsCID(optimizedImagePath), imageSize: formInput.imageProps?.optimizedImageSize ?? 0 },
      collectionOriginalImage = { uri: getIpfsCID(originalImagePath), imageSize: formInput.imageProps?.imageSize ?? 0 }

    /** For Videos */
    if (formInput?.isVideoCollectible) {
      collectionVideoIpfsUri = await fileIpfsUpload(formInput.video, collectionData.video, wallet);
      const optimizedImage = await compressImage(formInput.imageProps?.image, 1024, 2);
      collectionImageIpfsUri = await blobIpfsUpload(optimizedImage, collectionData.imageProps?.image, wallet);
    }
    /** End For Videos */

    else {
      const ImageFile: any = formInput.imageProps?.image;
      const optimizedImageFile: any = formInput?.imageProps?.optimizedImage;
      const thumbnailImageFile: any = formInput?.imageProps?.thumbnailImage;

      if (formInput.imageProps?.image !== collectionData.imageProps?.image && ImageFile?.type) {
        // const optimizedImage = await compressImage(formInput.imageProps?.image, 1024, 2);
        collectionOriginalImage.uri = await fileIpfsUpload(formInput.imageProps?.image, collectionData.imageProps?.image, wallet);
        collectionOriginalImage.imageSize = formInput.imageProps?.imageSize;
      }

      if (formInput.imageProps?.optimizedImage !== collectionData.imageProps?.optimizedImage && optimizedImageFile?.type) {
        // const optimizedImage = await compressImage(formInput.imageProps?.image, 1024, 2);
        collectionImageOptimized.uri = await fileIpfsUpload(formInput.imageProps?.optimizedImage, collectionData.imageProps?.optimizedImage, wallet);
        collectionImageOptimized.imageSize = formInput.imageProps?.optimizedImageSize;
      }
      if (formInput.imageProps?.thumbnailImage !== collectionData.imageProps?.thumbnailImage && thumbnailImageFile?.type) {
        // if(thumbnailFile.type=='image/gif'){
        // thumbnailImage = await compressImage(formInput.imageProps?.thumbnailImage, 300, 5);
        // }
        collectionImageThumbnail.uri = await fileIpfsUpload(formInput.imageProps?.thumbnailImage, collectionData.imageProps?.thumbnailImage, wallet);
        collectionImageThumbnail.imageSize = formInput.imageProps?.thumbnailImageSize;
      }
    }
    setLoading({ open: true, message: "Updating  details" });

    let collectionObj = formInput?.isVideoCollectible ?
      {
        "name": formInput?.name?.trim(),
        "description": formInput?.details?.trim(),
        "image": formInput?.isVideoCollectible ? "" : collectionImageIpfsUri,
        "video": collectionVideoIpfsUri,
        "subtitle": customType,
        "thumbnail": formInput?.isVideoCollectible ? collectionImageIpfsUri : ""
      } :
      {
        "name": formInput?.name?.trim(),
        "description": formInput?.details?.trim(),
        "image": collectionOriginalImage?.uri,
        "subtitle": customType,
        "imageSize": formInput?.imageProps?.imageSize,
        "thumbnailImage": collectionImageThumbnail?.uri,
        "thumbnailImageSize": collectionImageThumbnail?.imageSize,
        "optimizedImage": collectionImageOptimized?.uri,
        "optimizedImageSize": collectionImageOptimized?.imageSize,

      }
    if (formInput?.animationUrl) collectionObj["animation_url"] = formInput.animationUrl;
    if (formInput?.socialLinks && Object.keys(formInput?.socialLinks).length > 0) {
      if (formInput?.socialLinks?.discord !== "") {
        collectionObj["discord"] = formInput?.socialLinks?.discord;
      }
      if (formInput?.socialLinks?.telegram !== "") {
        collectionObj["telegram"] = formInput?.socialLinks?.telegram;
      }
      if (formInput?.socialLinks?.facebook !== "") {
        collectionObj["facebook"] = formInput?.socialLinks?.facebook;
      }
      if (formInput?.socialLinks?.twitter !== "") {
        collectionObj["twitter"] = formInput?.socialLinks?.twitter;
      }
      if (formInput?.socialLinks?.instagram !== "") {
        collectionObj["instagram"] = formInput?.socialLinks?.instagram;
      }
      if (formInput?.socialLinks?.tiktok !== "") {
        collectionObj["tiktok"] = formInput?.socialLinks?.tiktok;
      }
    }
    const ipfsDataURI = await jsonIpfsUpload(collectionObj, wallet);
    return ipfsDataURI;

  }

  const loadDetails = async (collectibleAddress, chainId) => {

    let newIndex = formInput.address === "" ?
      (allCollectionData && allCollectionData.length > 0 ?
        Number(allCollectionData[0].index) + 1 : 0)
      : formInput.index;

    if(type==CatalogTypes.EVENT){
      
      const eventDetails = await loadEventDetails({ networkID: networkId, provider: staticProvider,index:newIndex,eventAddress:collectibleAddress})
      dispatch(updateEventsData(eventDetails));
      return 

    }
    
    let collectibleDetails: ICollectibleDetail =
      await getCollectibleDetails({
        networkID: networkId, provider: staticProvider,
        userAddress: wallet?.address,
        collectibleData: { source: collectibleAddress, chainId },
        index: newIndex, entityAddress,
        isSuperAdmin: isSuperAdmin,
      },
        { isCache: false, fetchLatest: true });



    switch (type) {
      case CatalogTypes.PASSPORT:
      case CatalogTypes.EXPASSES:
        dispatch(updatePassportData(collectibleDetails));
        break;
      // case CatalogTypes.OFFERS:
      //   dispatch(updateRegularOfferData(collectibleDetails));
      //   break;
      case CatalogTypes.FEATUREDOFFERS:
        dispatch(updateFeaturedOfferData(collectibleDetails));
        break;
      
      case CatalogTypes.COLLECTIBLES:
        dispatch(updateDigitalCollectionData(collectibleDetails));
        break;
      case CatalogTypes.PREMIUM_CHAT:
        dispatch(updatePremiumChatData(collectibleDetails));
        break;
      case CatalogTypes.PRIVATE_CHAT:
        dispatch(updatePrivateChats(collectibleDetails));
        break;
    }
  }

  // console.log({formInput});

  const disableCollection = async () => {

    setShowConfirmPopup({ state: false, message: "", confirm: () => { } });
    setIsDisabledModal(false)

    if(type==CatalogTypes?.EVENT){
      setLoading({ open: true, message: `Removing ${customType}` });

      await dispatch(RemoveEvent({networkID:networkId,eventAddress:formInput?.address,address,wallet,EntityAddress:entityAddress}))
      dispatch(updateRemovedEvent(formInput))
    setSelectedCard(defaultCollection);

    setLoading({ open: false, message: "" });

      return 
    }else{

    

    const activeCollections = availablePassportDetails?.filter(i => i.isActive);
    const linkedCollectible = activeCollections?.filter(i => i.linkCollectible?.find(i => i.toLowerCase() == formInput?.address?.toLowerCase()));



    // if (linkedCollectible && linkedCollectible?.length) {
    //     setErrorStates({...errorStates,disableError:`It seems this collection is linked to ${linkedCollectible?.map(i=>i.name).join(',')}`})
    //     return
    // }


    setLoading({ open: true, message: `Retiring ${customType}` });
    const loot8Collection = Loot8UniformCollection__factory.connect(formInput?.address, getAnynetStaticProvider(formInput?.chain));
    const loot8CollectionOwnerAddress = await loot8Collection.owner();

    if (loot8CollectionOwnerAddress?.toLowerCase() != wallet?.address?.toLowerCase()) {
      setErrorStates({ ...errorStates, disableError: "Only Creator can disable this collection" })
      setLoading({ open: false, message: `` });

      return
    }

    // return 


    await dispatch(retireCollection({ networkID: networkId, provider: staticProvider, address, collectibleAddress: collectionData?.address, wallet }));

    }
    setLoading({ open: true, message: `Fetching updated details` });

    await loadDetails(collectionData?.address, collectionData.chain);
    setLoading({ open: false, message: "" });
    setSelectedCard(defaultCollection);
  }

  const onToggleSwitch = () => {
    // if (collectionData?.linkCollectible?.length) {
    //   setErrorStates({ ...errorStates, disableError: "You need to unlink collections to proceed" });
    //   return
    // }
    if (collectionData.isActive) {
      setIsDisabledModal(true);
      setShowConfirmPopup({ message: `Are you sure, you want to permanently disable ${customType}, "${collectionData.name}"`, confirm: () => { disableCollection() }, state: true, });
    }
  }

  const onToggleSocialMediaSwitch = (checked) => {

    setShowConfirmPopup({ message: socialMediaText, confirm: () => { onSocialMediaToggle(checked) }, state: true, });
  }
  const onSocialMediaToggle = async (checked: any) => {
    setShowConfirmPopup({ state: false, message: "", confirm: () => { } });

    setFormInput({ ...formInput, socialMedia: checked });
    const responseData = await dispatch(toggleCollectionSociaMedialAccess({ wallet, collectionAddress: collectionData.address, chainId: collectionData.chain, status: !checked }))
    if (collectionData.collectionType === CollectionType.PASSPORT) {
      dispatch(updatePassportSocialData({ ...collectionData, socialMedia: responseData.payload?.socialMedia }));
    }
    else if (type === CatalogTypes.COLLECTIBLES) {
      dispatch(updateCollectibleSocialData({ ...collectionData, socialMedia: responseData.payload?.socialMedia }));
    }
    else if (type === CatalogTypes.FEATUREDOFFERS) {
      dispatch(updateOfferSocialData({ ...collectionData, socialMedia: responseData.payload?.socialMedia }));

    }

  }

  const adornmentIcon = useMemo(() => (
    <InputAdornment position="start">
      {
        (collectionData.passportType === PassportType.SUBSCRIPTON || type == CatalogTypes.PREMIUM_CHAT || type === CatalogTypes.FEATUREDOFFERS) ?
          <img src={Loot8} height={20} width={20} alt='_'></img>
          :
          <span>$&nbsp;</span>
      }
    </InputAdornment>), [collectionData.passportType, type]);


  const isDisabled = useMemo(() => {
    if(type==CatalogTypes.EVENT && formInput?.owner?.toLowerCase()!=wallet?.address?.toLowerCase()){
      return true
    }
    if (isProduction && (wallet?.address.toLowerCase() == "0xBE92347869355689982d55C428d6b90084a851a6".toLowerCase() || isSuperAdmin)) {
      return false
    }
    if (!collectionData?.isActive || (type == CatalogTypes.EXPASSES && entityDetails?.entityOnboarded && !formInput?.isPremium)) {
      return true
    }
    return false
  }, [type, collectionData, entityDetails, formInput,showTicketCreation,wallet])

  const isTicketCreationDisabled = useMemo(()=>{
    if(formInput?.address!="" && collectionData?.eventTickets?.length==0 && wallet?.address.toLowerCase()==collectionData?.owner?.toLowerCase()){
      return false
    }
    return true
  },[formInput,collectionData,wallet])

  return (
    <>
    {type==CatalogTypes.EVENT && showTicketCreation &&
      <EventTickets 
      loadEvents={async ()=> {
        await loadDetails(formInput?.address,formInput?.chain)
        setSelectedCard(defaultCollection)
        setShowTicketCreation(false)
        setLoading({open:false,message:""})
      }}
      setLoading={setLoading}
      wallet={wallet} 
      entityAddress={entityAddress}
      address={address}
      provider={staticProvider} 
      networkId={networkId}
      eventData={formInput} 
      setShowTicketCreation={setShowTicketCreation}/>
    }
      {formInput?.address != "" && showActive ?
        <CollectionTransfer
          {...collectionTransferProps}
          selectedCard={formInput}
        /> : <></>}
      {  !showTicketCreation && type==CatalogTypes.EVENT && 
        <Box sx={{ display: 'flex', alignItems: 'flex-end',justifyContent:'flex-end' }}>
          <CustomButton
            className={"col-12 col-lg-2 col-xs-12 col-sm-12"}
            onClick={() => setShowTicketCreation(!showTicketCreation)}
            color={defaultTheme.buttonDarkTextColor}
            backgroundColor={defaultTheme.yellowColor}
            // disabled={false}
            disabled={isTicketCreationDisabled}
            type={"button"}
          >
            Create/Generate Ticket
          </CustomButton>
        </Box>
      }
      {!collectionTransferProps?.isTransferCollection &&  !showTicketCreation && 
        <div>
          {isCollectibleModalOpen && collectionData?.address === formInput?.address ?
            <div
              id='edit-collectible-container'
              className={`fade-in`}>
              <EditCollectibleModal
                allUserData={allUserData}
                allTheLinkedCollectionsInChats={allTheLinkedCollectionsInChats}
                collectionData={collectionData}
                isFormForNewPassport={isFormForNewPassport}
                wallet={wallet}
                formInput={formInput}
                setFormInput={setFormInput}
                type={type}
                setCategory={setCategory}
                modalOpen={isCollectibleModalOpen}
                onModalClose={() => onModalClose(false)}
                gpsChecked={gpsChecked}
                setGpsChecked={setGpsChecked}
                setLimitedCountChecked={setLimitedCountChecked}
                setMaxBalanceChecked={setMaxBalanceChecked}
                setTierCollection={setTierCollection}
                setMaxTokens={setMaxTokens}
                maxTokens={maxTokens}
                tierCollection={tierCollection}
                maxBalanceChecked={maxBalanceChecked}
                limitedCountChecked={limitedCountChecked}
                onCreationSwitch={onCreationSwitch}
                onSubmitHandler={onSubmitHandler} />
            </div> :

            <FormGroup className='h-100' >
              <GridContainer container paddingBottom={'1rem'} >
                <Grid sx={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                  <GridItem id='walkthrough' item xs={12} sm={12} md={4} lg={4} xl={4}  >

                    <NameTextInput
                      label={`${customType} Name`}
                      placeholder={`Name your ${customType}`}
                      name={collectionData.name}
                      nameChangeHandler={null}
                      required={false} disabled={true} />

                    <DescriptionTextInput description={collectionData.details} descriptionChangeHandler={null} required={false} disabled={true} />
                    {
                      !(type === CatalogTypes.COLLECTIBLES) &&
                      <>
                        <PriceInput
                          label={'Price'}
                          placeholder={(collectionData.passportType === PassportType.SUBSCRIPTON || collectionData.offerType == OfferType.FEATURED) ? 'Fees' : 'Enter a Price'}
                          price={collectionData.price}
                          priceChangeHandler={null}
                          required={formInput.passportType === PassportType.SUBSCRIPTON || collectionData.offerType == OfferType.FEATURED ? true : false}
                          disabled={true}
                          adornment={adornmentIcon} />
                        {(formInput.passportType === PassportType.SUBSCRIPTON || collectionData.offerType == OfferType.FEATURED) &&
                          <Box display={'flex'} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                            <Typography variant="caption" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins'}>
                              Users to pay {(Number(collectionData?.price) + (Number(collectionData?.price) * 0.2)).toFixed(2)} (+20%) Loot8 tokens
                            </Typography>
                            <LightTooltip title={"Kindly be aware that the total cost for users will amount to +20% loot8 tokens, encompassing a 10% loot8 tokens as subscription fee allocated to you and another 10% loot8 tokens directed to Loot8."} placement="bottom" arrow>
                              <InfoRoundedIcon sx={{ fontSize: '1rem' }} color='disabled' />
                            </LightTooltip>
                          </Box>
                        }
                      </>
                    }

                    {/* display fields only if its an organizational user */}
                    {!isEntityOnboarded && type != CatalogTypes?.PREMIUM_CHAT ? <>

                      <BasicTextInput value={customType} valueChangeHandler={null} label={'Type'} placeholder={""} required={false} disabled={true} />

                      {/* <DatePickerComponent label={"Start Date"} selectedDate={collectionData.start} datechangedHandler={null} disabled={true} />
       <DatePickerComponent label={"End Date"} selectedDate={collectionData.end} datechangedHandler={null} disabled={true} /> */}

                    </> : null}
                  </GridItem>

                  <GridItem item xs={12} sm={12} md={4} lg={4} xl={4} id='image-walkthrough' >
                    <div id='upload-container' className='bac' >
                      <Typography className='preview-text' fontFamily={'Poppins-Medium'}>Preview</Typography>
                      <div className="corner-border bottom-left" />
                      <div className="corner-border bottom-right" />
                      <div className="corner-border top-right" />
                      <div className="corner-border top-left" />
                      <ImageUploadCard background={collectionData.isVideoCollectible ? backgroundVideo : backgroundImage} backgroundImageChangeHandler={null}
                        fileTypes={type === CatalogTypes.COLLECTIBLES ? "image/jpg, image/jpeg, image/png, video/*" : "image/jpg, image/jpeg, image/png"} isVideo={collectionData.isVideoCollectible} isDisabled={true} />
                    </div>
                  </GridItem>

                  <GridItem item xs={12} sm={12} md={4} lg={4} xl={4}  >
                    <div id='advance-setting-container' className='detail-container'>
                      <Accordion
                        id='accordion-settings' 
                        sx={{ backgroundColor: 'transparent', margin: 0 }}
                        elevation={0}
                        disableGutters
                        expanded={expanded}
                        onChange={handleChange}
                      >
                        <AccordionSummary
                          sx={{ margin: 0, borderBottom: '1px solid #70707070', }}
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography variant="body2"
                            color={defaultTheme.buttonDarkTextColor}
                            fontFamily={'Poppins-Medium'}>
                            Advanced Settings
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>

                          {!isEntityOnboarded && <>
                            <SelectInput
                              formContainer={true}
                              label={collectionData.collectionType === CollectionType.PASSPORT ? "Associated Collectibles" : "Associated Passports"}
                              menuData={collectionData.collectionType === CollectionType.PASSPORT ? availableCollectibleDetails.filter(obj => obj.isActive) : availablePassportDetails.filter(obj => obj.isActive)}
                              handleOptionChange={null}
                              selectedOptions={collectionData.linkCollectible}
                              disabled={true}
                            />
                          </>}

                          <>
                            <CustomCheckbox                          
                              label={`Limited ${type == CatalogTypes.PREMIUM_CHAT ? "Participants" : customType}`}
                              checked={!collectionData.isCoupon && limitedCountChecked}
                              handleCheckChange={null} disabled={true} />
                            <LightTooltip title={limitedInfoText + (!collectionData?.marketPlaceConfig?.allowMarketplaceOps ? " Please reach out to Loot8 to whitelist the collection for enabling tradability options." : "")} placement="bottom" arrow>
                              <InfoRoundedIcon className='mt-2' fontSize='small' color='disabled' />
                            </LightTooltip>

                            {limitedCountChecked && !collectionData.isCoupon &&
                              <CountInput label={`Maximum ${customType} Offered`} count={collectionData.maxMint} countChangeHandler={null} required={limitedCountChecked} disabled={true} />
                            }
                          </>


                          {(((type === CatalogTypes.EXPASSES || type === CatalogTypes.COLLECTIBLES || type == CatalogTypes.PREMIUM_CHAT || type == CatalogTypes.FEATUREDOFFERS))) && formInput?.marketPlaceConfig &&
                            <div id='tradable-container' className='d-flex align-items-center flex-nowrap'>
                              <CustomCheckbox
                                label={`Tradable in Marketplace`}
                                checked={collectionData?.marketPlaceConfig?.publicTradeAllowed}
                                handleCheckChange={null}
                                disabled={true} />

                              <LightTooltip title={infoText + (!collectionData?.marketPlaceConfig?.allowMarketplaceOps ? " Please reach out to Loot8 to whitelist the collection for enabling tradability options." : "")} placement="bottom" arrow>
                                <InfoRoundedIcon className='mt-2' fontSize='small' color='disabled' />
                              </LightTooltip>
                            </div>
                          }

                          {/* {type==CatalogTypes.PREMIUM_CHAT ? 
             <div className='mt-2'>
               <CustomSwitch disabled={false} checked={formInput?.socialMedia} handleToggle={onSocialMediaToggle} label={"Enable Chat Room"} />
             </div> : null
             } */}

                          {((collectionData.collectionType === CollectionType.PASSPORT) || (collectionData.collectionType === CollectionType.COLLECTION)) && collectionData.address !== "" &&

                            <div id='social-media-container' className='mt-2 d-flex align-items-center flex-nowrap'>
                              <CustomSwitch disabled={false} checked={formInput?.socialMedia} handleToggle={!formInput?.socialMedia ? onSocialMediaToggle : onToggleSocialMediaSwitch} label={"Enable Social Media"} />
                              <LightTooltip id='tool-tip-light' title={socialMediaText} placement="bottom" arrow>
                                <InfoRoundedIcon className='mt-2' fontSize='small' color='disabled' />
                              </LightTooltip>
                            </div>
                          }
                        </AccordionDetails>

                      </Accordion>

                      {(collectionData?.address !== "" && collectionData?.isActive) || (collectionData?.address !== "" && type == CatalogTypes.PREMIUM_CHAT && collectionData?.linkCollectible?.length) ?
                        <>
                          <Box                          
                            sx={{
                              justifyContent: 'flex-end'
                            }}
                            className="button-container form-buttons d-flex justify-content-around mt-3">
                            <CustomButton
                              className={"col-5 col-sm-4 col-lg-5"}
                              onClick={onToggleSwitch}
                              color={defaultTheme.buttonDarkTextColor}
                              backgroundColor={defaultTheme.buttonRedColor}
                              // disabled={false}
                              disabled={isDisabled}
                              type={"button"}
                            >
                              {type==CatalogTypes.EVENT ? "Remove" : "DISABLE"}
                            </CustomButton>
                            <CustomButton
                              className={"col-5 col-sm-4 col-lg-5"}
                              onClick={onModalOpen}
                              color={defaultTheme.buttonDarkTextColor}
                              backgroundColor={defaultTheme.yellowColor}
                              type={"button"}
                              disabled={false}
                            >
                              {`EDIT ${customType}`}
                            </CustomButton>

                          </Box>
                          {errorStates?.disableError &&
                            <Box p={2}>
                              <ErrorText message={errorStates?.disableError} />
                            </Box>}
                        </> : <></>
                      }
                      <Box>


                      </Box>
                    </div>
                  </GridItem>
                </Grid>
              </GridContainer>
            </FormGroup>

          }
        </div>
      }
      {
        showConfirmPopup.state && <ConfirmModal
          children={
            (type == CatalogTypes.EXPASSES || type == CatalogTypes.PREMIUM_CHAT) && !isDisabledModal ?
              <>
                <Typography fontFamily={'Poppins-Medium'} fontSize={'1rem'}
                  sx={{
                    color: '#5e5eaa',
                    padding: 1,
                    marginInline: '1rem'
                  }}
                  textAlign={'center'}>
                  Kindly be aware that individuals opting for access to this {type == CatalogTypes.EXPASSES ? "ExPass" : type} will be required to make a payment of {(Number(formInput?.price) + (Number(formInput?.price) * 0.2)).toFixed(2)} loot8 token{Number(Number(formInput?.price).toFixed(0)) > 1 ? "s" : ""}.
                  <LightTooltip title={"Kindly be aware that the total cost for users will amount to +20% loot8 tokens, encompassing a 10% loot8 tokens as subscription fee allocated to you and another 10% loot8 tokens directed to Loot8."} placement="bottom" arrow>
                    <InfoRoundedIcon sx={{ fontSize: '1rem' }} color='disabled' />
                  </LightTooltip>
                </Typography>
                {formInput?.marketPlaceConfig?.publicTradeAllowed &&
                  <Typography fontFamily={'Poppins-Medium'} fontSize={'1rem'}
                    sx={{

                      color: '#5e5eaa',
                      padding: 1,
                      marginInline: '1rem'
                    }}
                    textAlign={'center'}>
                    {"Note: Market place tradability is enabled and cannot be disabled later"}
                  </Typography>
                }
              </>
              : <></>
          }
          cancelText='No'
          confirmText='Yes'
          modalOpen={showConfirmPopup.state} text={showConfirmPopup.message} onModalCancel={onModalCancel} onModalSubmit={showConfirmPopup.confirm} />
      }
    </>

  );
}