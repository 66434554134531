import { Client, createRequest, fetchExchange } from 'urql';
import { collectibleTransfersQuery, collectionAddedEventQuery, collectionAdditionalDataQuery, collectionDataQuery, collectionDetailsCombinedQuery, collectionMetadataQuery, fetchPOAPCollectionsQuery, whitelistedCollectionsQuery } from '../graphQLQueries/collection';
import { usersDataQuery } from '../graphQLQueries/users';
import { LogToConsoleError } from './Logger';
import { INDEXER_SERVICE_URL } from '../constants';
import { CollectionAddedEvent } from '../typechain/CollectionManager';
import { entityDataQuery } from '../graphQLQueries/entity';
import { IUsers } from '../graphQLQueries/IUsers';

     
const fetchData = async (query: any, params: any) => {

    // https://formidable.com/open-source/urql/docs/basics/react-preact/
    const client = new Client({
        url: `${INDEXER_SERVICE_URL}/graphql`,
        exchanges: [fetchExchange]
    });

    const request = createRequest(query, {
        ...params
    })
    try {
        const response = await client.executeQuery(request);
        return response?.data ?? null;
    } catch(e) {
        LogToConsoleError("Error while fetching GraphQL Data", e);
        return null;
    }
    
}

export const fetchCollectionData = async (collectionAddress: string): Promise<CollectionData | null> => {
    const responseData = await fetchData(collectionDataQuery, {
        address: collectionAddress
    });
    return responseData?.collectionData ?? null;
}

export const fetchCollectionMetaData = async (collectionAddress: string): Promise<CollectionMetadata | null> => {
    const responseData = await fetchData(collectionMetadataQuery, {
        address: collectionAddress
    });
    return responseData?.collectionMetadata ?? null;
}

export const fetchCollectionAdditionalData = async (collectionAddress: string): Promise<CollectionDataAdditional | null> => {
    const responseData = await fetchData(collectionAdditionalDataQuery, {
        address: collectionAddress
    });
    return responseData?.collectionDataAdditional ?? null;
}

export const fetchCollectionDetailsCombined = async (collectionAddress: string): Promise<{
    collectionDataAdditional: CollectionDataAdditional,
    collectionMetadata: CollectionMetadata,
    collectionData: CollectionData
} | null> => {
    const responseData = await fetchData(collectionDetailsCombinedQuery, {
        address: collectionAddress
    });
    return responseData ? { 
        collectionDataAdditional: responseData?.collectionDataAdditional ?? null,
        collectionMetadata: responseData?.collectionMetadata ?? null,
        collectionData: responseData?.collectionData ?? null
    } : null;
}

export const fetchCollectionAddedEvent = async (collectionAddress: string): Promise<CollectionAddedEvent | null> => {
    const responseData = await fetchData(collectionAddedEventQuery, {
        address: collectionAddress
    });
    return responseData?.collectionAddedEvent ?? null;
}

export const fetchWhitelistedCollections = async(passportAddress: string) : Promise<WhitelistedCollections[] | null> => {
    const responseData = await fetchData(whitelistedCollectionsQuery, {
        address: passportAddress
    });
    return responseData?.whitelistedCollectionsForPassport?.whitelistedCollections ?? null;
}

export const fetchUsersData = async (page: number, pageSize: number): Promise<IUsers | null> => {
    const responseData = await fetchData(usersDataQuery, {
        page: page,
        pageSize: pageSize
    });
    return responseData?.users ?? null;
}

export const fetchAllEntityDetails = async (isActive: boolean): Promise<EntityDetails[] | null> => {
    const responseData = await fetchData(entityDataQuery, {isActive: isActive});
    return responseData?.entitiesData?.records ?? null;
}

export const fetchCollectibleTransfers = async (address: string, from: string, page: number, pageSize: number): Promise<ICollectibleTransferEventIndexerResponse | null> => {
    const responseData = await fetchData(collectibleTransfersQuery, {address: address, from: from, page: page, pageSize: pageSize});
    return responseData?.collectibleTransfers ?? null;
}

export const fetchPOAPCollections = async(entity:string) => {
    const responseData = await fetchData(fetchPOAPCollectionsQuery,{entity});
    return responseData?.poapCreatedCollections ?? null;
}