import { gql } from "urql";

export const usersDataQuery = gql`
  query($page: Int, $pageSize: Int) {
    users(page:$page, pageSize: $pageSize) {
        records {
          wallet
          userId
          name
          dataURI
          avatarURI
        }
        count
      }
  }
`;
