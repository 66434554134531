import { ethers } from "ethers";
import { ZERO_ADDRESS } from "../constants";
import {  CollectionFactory__factory, CollectionHelper__factory, CollectionManager__factory, Dispatcher__factory, Entity__factory, EventFactory__factory, EventRegistry__factory, Event__factory, ExternalCollectionManager__factory, Loot8Onboarder__factory, Loot8UniformCollection__factory, POAPCollectionFactory__factory, SubscriptionManager__factory, TicketFactory__factory, TieredCouponCollectionFactory__factory, User__factory } from "../typechain/factories";

import { LogToConsoleError } from "./Logger";

export const GetCreateUserMessage = (name, imageUrl, dataURI) => {
    const IUser = new ethers.utils.Interface(Dispatcher__factory.abi);
    return IUser.encodeFunctionData('registerUser', [name, imageUrl, dataURI]);
}

export const GetUserCreatedLogData = (logs) => {
    let decodedEventData = null;
    let createdUserID = 0;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            const IUser = new ethers.utils.Interface(User__factory.abi);
            decodedEventData = IUser.decodeEventLog("UserCreated", logs[0].data, logs[0].topics)
        }
        if (decodedEventData && Array.isArray(decodedEventData) && decodedEventData.length > 1) {
            createdUserID = parseInt(decodedEventData[1], 10);
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding user created event logs');
    }
    return createdUserID;
}

export const GetToggleBartenderMessage = (address) => {
    const IEntity = new ethers.utils.Interface(Entity__factory.abi);
    return IEntity.encodeFunctionData('toggleBartender', [address]);
}

export const GetAddBartenderMessage = (address) => {
    const IEntity = new ethers.utils.Interface(Entity__factory.abi);
    return IEntity.encodeFunctionData('addBartender', [address]);
}

export const GetUpdateEntityDataUriMessage = (dataURI) => {
    const IEntity = new ethers.utils.Interface(Entity__factory.abi);
    return IEntity.encodeFunctionData('updateDataURI', [dataURI]);
}

export const GetUpdateCollectionDataUriMessage = (address, dataURI) => {
    const ICollectionHelper = new ethers.utils.Interface(CollectionHelper__factory.abi);
    return ICollectionHelper.encodeFunctionData('updateContractURI', [ address, dataURI]);
}

export const GetCreateTicketCollection = (_eventAddress, _ticketName,_symbol,_contractURI,_transferable,_manager,_helper,_layerZeroAddress) => {
    const ITicketCreation = new ethers.utils.Interface(TicketFactory__factory.abi);
    return ITicketCreation.encodeFunctionData('createTicketCollection', [ _eventAddress, _ticketName,_symbol,_contractURI,_transferable,_manager,_helper,_layerZeroAddress]);
}

export const GetUpdateCollectionDataUriOtherMessage = (dataURI) => {
    const ICollectionHelper = new ethers.utils.Interface(Loot8UniformCollection__factory.abi);
    return ICollectionHelper.encodeFunctionData('updateContractURI', [dataURI]);
}

export const GetRetireCollectionMessage = (address) => {
    const ICollection = new ethers.utils.Interface(CollectionManager__factory.abi);
    return ICollection.encodeFunctionData('retireCollection', [ address ]);
}

export const GetRemoveEventMessage = (_address,_entity) => {
    const IEventRemoved = new ethers.utils.Interface(EventRegistry__factory.abi);
    return IEventRemoved.encodeFunctionData('removeEventAddress', [ _address,_entity ]);
}

export const GetLinkCollectionMessage = (address1, addresses2) => {
    const ICollectionHelper = new ethers.utils.Interface(CollectionHelper__factory.abi);
    return ICollectionHelper.encodeFunctionData('linkCollections', [ address1, addresses2 ]);
}

export const GetDelinkCollectionMessage = (address1, address2) => {
    const ICollectionHelper = new ethers.utils.Interface(CollectionHelper__factory.abi);
    return ICollectionHelper.encodeFunctionData('delinkCollection', [ address1, address2 ]);
}

export const GetWhitelistCollectionMessage = (address1,chainId, address2) => {
    const IExternalCollectionManager = new ethers.utils.Interface(ExternalCollectionManager__factory.abi);
    return IExternalCollectionManager.encodeFunctionData('whitelistCollection', [ address1, chainId, address2 ]);
}

export const GetDelistCollectionMessage = (address1, chainId, address2) => {
    const IExternalCollectionManager = new ethers.utils.Interface(ExternalCollectionManager__factory.abi);
    return IExternalCollectionManager.encodeFunctionData('delistCollection', [ address1, chainId, address2 ]);
}

export const GetAddCollectionMessage = (address, chainId, collectionType, collectibleData, additionalCollectionData, area, _tradability ) => {
    const ICollection = new ethers.utils.Interface(CollectionManager__factory.abi);
    return ICollection.encodeFunctionData('addCollection', [ address, chainId, collectionType, collectibleData, additionalCollectionData, area, _tradability ]);
}

export const GetCreateCollectionMessage = (address, name, symbol, dataURI, _transferable, manager, helper, subscriptionManager, _layerZeroEndpoint) => {
    const ICollectionFactory = new ethers.utils.Interface(CollectionFactory__factory.abi);
    return ICollectionFactory.encodeFunctionData('createCollection', [ address, name, symbol, dataURI, _transferable, manager, helper, subscriptionManager, _layerZeroEndpoint ]);
}

export const GetCreateCouponCollectionMessage = (address, name, symbol, dataURI, _transferable, manager, helper, _layerZeroEndpoint, tierCollection, maxTokens) => {
    const ICouponCollectionFactory = new ethers.utils.Interface(TieredCouponCollectionFactory__factory.abi);
    return ICouponCollectionFactory.encodeFunctionData('createCollection', [ address, name, symbol, dataURI, _transferable, manager, helper, _layerZeroEndpoint, tierCollection, maxTokens ]);
}

export const GetPOAPCreateCollectionMessage = (address, name, symbol,dataURI, _transferable, _layerZeroEndpoint, autoStartMint ) => {
    const IPOAPCollectionFactory = new ethers.utils.Interface(POAPCollectionFactory__factory.abi);
    return IPOAPCollectionFactory.encodeFunctionData('createCollection', [ address, name, symbol, dataURI, _transferable, _layerZeroEndpoint, autoStartMint ]);
}

export const GetUpdateCollectionDataMessage = (address, collectibleData, additionalCollectionData , area ) => {
    const ICollection = new ethers.utils.Interface(CollectionManager__factory.abi);
    return ICollection.encodeFunctionData('updateCollection', [ address, collectibleData, additionalCollectionData, area ]);
}

export const GetCreateEventDataMessage = (eventData,area) => {
    const IEventTicket = new ethers.utils.Interface(EventFactory__factory.abi);
    return IEventTicket.encodeFunctionData('createEvent',[eventData,area])
}

export const GetUpdateEventDataMessage = (eventData,area) => {
    const IEventTicket = new ethers.utils.Interface(Event__factory.abi);
    return IEventTicket.encodeFunctionData('updateEvent',[eventData,area])
}


export const GetAddCollaboratorsMessage = (addresses) => {
    const IEvent = new ethers.utils.Interface(Event__factory.abi);
    return IEvent.encodeFunctionData('inviteCollaborators',[addresses])
}

export const GetRemoveCollaboratorsMessage = (_collaborator,_status) => {
    const IEvent = new ethers.utils.Interface(Event__factory.abi);
    return IEvent.encodeFunctionData('setCollaboratorStatus',[_collaborator,_status])
}

export const GetAddGateKeeperMessage = (addresses) => {
    const IEvent = new ethers.utils.Interface(Event__factory.abi);
    return IEvent.encodeFunctionData('addGatekeepers',[addresses])
}

export const GetRemoveGateKeeperMessage = (_gatekeeper,_status) => {
    const IEvent = new ethers.utils.Interface(Event__factory.abi);
    return IEvent.encodeFunctionData('setGatekeeperStatus',[_gatekeeper,_status])
}


export const GetCreateEntityDataMessage = (area, entityName, dataURI, walletAddress, authority, userContract, onboarder) => {
    const IEntity = new ethers.utils.Interface(Entity__factory.abi);
    return IEntity.encodeFunctionData('initializeV2', [ area, entityName, dataURI, walletAddress, authority, userContract, onboarder ]);
}

export const GetOnboardEntityDataMessage = (inviteCode, creationData) => {
    const IOnboarder = new ethers.utils.Interface(Loot8Onboarder__factory.abi);
    return IOnboarder.encodeFunctionData('onboard', [ inviteCode, creationData ]);
}

export const GetAddInviteCodeDataMessage = (inviteCodes) => {
    const IOnboarder = new ethers.utils.Interface(Loot8Onboarder__factory.abi);
    return IOnboarder.encodeFunctionData('addInviteHash', [ inviteCodes ]);
}

export const GetSubscriptionPassportDataMessage = (_passport, _peopleFeeReceiver, _floorPrice, _priceRate, _tradingEnabled, _startSubscription) => {
    const ISubscription = new ethers.utils.Interface(SubscriptionManager__factory.abi);
    return ISubscription.encodeFunctionData('setCollectionSubscriptionConfig', [ _passport,_peopleFeeReceiver, _floorPrice, _priceRate, _tradingEnabled, _startSubscription]);
}

export const GetMarketPlaceTradablityMessage = (_collection, _privateTradeAllowed, _publicTradeAllowed) => {
    const ICollectionHelper = new ethers.utils.Interface(CollectionHelper__factory.abi);
    return ICollectionHelper.encodeFunctionData('setTradeablity', [ _collection, _privateTradeAllowed, _publicTradeAllowed ]);
}

export const GetEncodedHash = (inviteCode : string) => {
    const abi = ethers.utils.defaultAbiCoder;
    return ethers.utils.keccak256(abi.encode(["string"], [inviteCode]));
}

export const GetAddEntityAdminDataMessage = (creatorAddress) => {
    const IEntity = new ethers.utils.Interface(Entity__factory.abi);
    return IEntity.encodeFunctionData('addEntityAdmin', [creatorAddress]);
}


export const GetEventCreatedLogData = (logs) => {
    let createdEvent = ZERO_ADDRESS;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            const IEventFactory = new ethers.utils.Interface(EventFactory__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedEventDataItem = IEventFactory.parseLog(item);                  
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "EventCreated".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args[0]) {     
                                    
                            createdEvent = decodedEventDataItem?.args[0];
                    }
                }
                catch(e) {}
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    
    return createdEvent;
}

export const GetTicketCreatedLogData = (logs) => {
    let createdTicket = ZERO_ADDRESS;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            const ITicketFactory = new ethers.utils.Interface(TicketFactory__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedTicketDataItem = ITicketFactory.parseLog(item);    
                                  
                    if(decodedTicketDataItem 
                        && (decodedTicketDataItem?.name.toLocaleLowerCase() === "CreatedTicket".toLocaleLowerCase())
                        && decodedTicketDataItem?.args
                        && decodedTicketDataItem?.args[0]) {     
                                        
                            createdTicket = decodedTicketDataItem?.args?._loot8TicketCollection;
                    }
                }
                catch(e) {}
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    
    return createdTicket;
}

export const GetCollectionCreatedLogData = (logs) => {
    let createdCollection = ZERO_ADDRESS;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            const ICollectionFactory = new ethers.utils.Interface(CollectionFactory__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedEventDataItem = ICollectionFactory.parseLog(item);                    
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "CreatedCollection".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args?._collection) {                       
                            createdCollection = decodedEventDataItem?.args?._collection;
                    }
                }
                catch(e) {}
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    return createdCollection;
}

export const GetCollectionUpdatedLogData = (logs) => {

    let updatedCollection = ZERO_ADDRESS;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            //SubscriptionManager__factory
            const ICollectionFactory = new ethers.utils.Interface(CollectionManager__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedEventDataItem = ICollectionFactory.parseLog(item);               
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "CollectionDataUpdated".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args?._collection) {                       
                            updatedCollection = decodedEventDataItem?.args?._collection;
                    }
                }
                catch(e) {}
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    return updatedCollection;
}

export const GetAddSubscriptionPassportLogData = (logs) => {

    let subscribedPassport = ZERO_ADDRESS;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            //SubscriptionManager__factory
            const ISubscriptionPassport = new ethers.utils.Interface(SubscriptionManager__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedEventDataItem = ISubscriptionPassport.parseLog(item);                   
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "SubscriptionConfigSet".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args?._collection) {                       
                            subscribedPassport = decodedEventDataItem?.args?._collection;
                    }
                }
                catch(e) {
                }
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    return subscribedPassport;
}

export const GetEntityOnboardedLogData = (logs) => {

    let createdEntity = ZERO_ADDRESS;
    try {
        if (logs && Array.isArray(logs) && logs.length > 0) {
            const IOnboarder = new ethers.utils.Interface(Loot8Onboarder__factory.abi);
                let item = logs[logs.length - 1];
                try {
                    const decodedEventDataItem = IOnboarder.parseLog(item);                    
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "EntityOnboarded".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args?._entity) {                       
                            createdEntity = decodedEventDataItem?.args?._entity;
                    }
                }
                catch(e) {
                    LogToConsoleError("error in decoding onboarding entity event log");
                }
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding onboarding entity event logs',e);
    }
    return createdEntity;
}

export const GetPOAPCollectionCreatedLogData = (logs, chain) => {

    let createdCollection = ZERO_ADDRESS;
    try {
        if(logs && Array.isArray(logs) && logs.length > 0) {
            const IPOAPCollectionFactory = new ethers.utils.Interface(POAPCollectionFactory__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedEventDataItem = IPOAPCollectionFactory.parseLog(item);                    
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "CreatedCollection".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args?._collection) {                       
                            createdCollection = decodedEventDataItem?.args?._collection;
                    }
                }
                catch(e) {}
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    return createdCollection;
}

export const GetCouponCollectionCreatedLogData = (logs, chain) => {

    let createdCollection = ZERO_ADDRESS;
    try {
        if(logs && Array.isArray(logs) && logs.length > 0) {
            const ICouponCollectionFactory = new ethers.utils.Interface(TieredCouponCollectionFactory__factory.abi);
            logs?.map((item, index) => {
                try {
                    const decodedEventDataItem = ICouponCollectionFactory.parseLog(item);                    
                    if(decodedEventDataItem 
                        && (decodedEventDataItem?.name.toLocaleLowerCase() === "CreatedCollection".toLocaleLowerCase())
                        && decodedEventDataItem?.args
                        && decodedEventDataItem?.args?._collection) {                       
                            createdCollection = decodedEventDataItem?.args?._collection;
                    }
                }
                catch(e) {}
            })
        }
    }
    catch (e) {
        LogToConsoleError('error in decoding collection created event logs',e);
    }
    return createdCollection;
}

export const GetUserNameMessage = (name) => {
    const IUser = new ethers.utils.Interface(User__factory.abi);
    return IUser.encodeFunctionData('changeName', [name]);
}

export const GetUserStatusMessage = (status) => {
    const IUser = new ethers.utils.Interface(User__factory.abi);
    return IUser.encodeFunctionData('setStatus', [status]);
}

export const GetUserAvatarMessage = (avatarURI) => {
    const IUser = new ethers.utils.Interface(User__factory.abi);
    return IUser.encodeFunctionData('setAvatarURI', [avatarURI]);
}

export const GetUserDataURIMessage = (dataURI) => {
    const IUser = new ethers.utils.Interface(User__factory.abi);
    return IUser.encodeFunctionData('setDataURI', [dataURI]);
}