import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, InputAdornment, MenuItem, Select, Typography, outlinedInputClasses, useTheme } from "@mui/material";
import './EditCollectibleModal.scss'
import { NameTextInput } from '../../../../components/TextInput/NameTextInput/NameTextInput';
import { DescriptionTextInput } from '../../../../components/TextInput/DescriptionTextInput/DescriptionTextInput';
import { PriceInput } from '../../../../components/TextInput/NumberInput/PriceInput';
import { BasicTextInput } from '../../../../components/TextInput/BasicTextInput/BasicTextInput';
import { DatePickerComponent } from '../../../../components/DatePicker/DatePicker';
import { CustomCheckbox } from '../../../../components/CheckBox/CheckBox';
import { CustomButton } from '../../../../components/Button/CustomButton';
import { defaultTheme } from '../../../../styles';
import { useAppSelector, useWeb3Context } from '../../../../hooks';
import { SelectInput } from '../../../../components/Dropdown/SelectDropdown/SelectInputDropdown';
import { ImageCard } from '../../components/FileUploadCard/ImageCard';
import { CATEGORIES, OfferType, PassportType } from '../../../../enums/offers.enum';
import NetworkSelect from '../../../../components/Dropdown/NetworkSelect/NetworkSelect';
import { DiscordRegex, TelegramRegex, latRegex, lonRegex, FacebookRegex, TwitterRegex, InstagramRegex, TikTokRegex } from '../../../../utils/validators';
import { CoordinateInput } from '../../../../components/TextInput/NumberInput/CoordinateInput';
import { CountInput } from '../../../../components/TextInput/NumberInput/CountInput';
import { AddressInput } from '../../../../components/TextInput/AddressInput/AddressInput';
import { LightTooltip } from '../FormContainer.tsx/CollectionFormContainer';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { CatalogTypes } from '../../../../enums/catalog.enum';
import Loot8 from "../../../../assets/images/logos/Loot8Token.png"
import { Categories, IMAGE_REGEX, VIDEO_REGEX, isProduction } from '../../../../constants';
import { getFirstFrameDataUrl, getThumbnailFromVideo } from '../../../../slices/helpers';
import { useAlert } from '../../../../hooks/Alert';
import { ConfirmModal } from '../../../../components/ModalComponents/ConfirmModal';
import { ExpandMore } from '@mui/icons-material';
import { CollectionType } from '../../../../enums/collection.enum';
import { compressImage } from '../../../../services/imageHelper.service';
import SocialLinksComponent from '../../../../components/SocialLink/SocialLinkComponent';

import discord from '../../../../assets/images/social/discordColor.png';
import facebook from '../../../../assets/images/social/facebookColor.png';
import twitter from '../../../../assets/images/social/twitterColor.png';
import telegram from '../../../../assets/images/social/telegramColor.png';
import instagram from '../../../../assets/images/social/instagramColor.png';
import tiktok from '../../../../assets/images/social/tiktokColor.png';
import MyLineChart from '../../../../components/Chart';
import { CollaboratorDropDown } from '../components/CollaboratorDropDown';

type imageType = "thumbnail" | "optimised" | "original"

const infoText = "Enabling tradability will allow the collection to be tradable in the market place. Note: Once enabled, market place tradability cannot be disabled again.";

const EditCollectibleModal = ({ allTheLinkedCollectionsInChats, wallet, modalOpen, onModalClose, collectionData, formInput, setFormInput, type,
  gpsChecked, setGpsChecked, allUserData, isFormForNewPassport, setLimitedCountChecked, setMaxBalanceChecked, setTierCollection, setMaxTokens, maxTokens, tierCollection,
  maxBalanceChecked, limitedCountChecked, setCategory, onCreationSwitch, onSubmitHandler }) => {

  let singleCollectible = type == CatalogTypes.PREMIUM_CHAT ?
    formInput.linkCollectible[0] : null

  const [backgroundImage, setBackgroundImage] = useState(collectionData.imageProps?.image);
  const [thumbnailImage, setThumbnailImage] = useState(collectionData?.imageProps?.thumbnailImage);
  const [optimizedImage, setOptimizedImage] = useState(collectionData?.imageProps?.optimizedImage);
  const [backgroundVideo, setBackgroundVideo] = useState(collectionData?.video);
  
  const [batchLimit, setBatchLimit] = useState({
    collaborators: 0,
    gatekeeper: 0,
  });

  const { setShowToastError } = useAlert();
  // const canvasRef = useRef()

  const entityDetails = useAppSelector(state => state.Entity.EntityData);
  const availablePassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails) ?? [];
  let availbleFeaturedOffers = useAppSelector(state => state.Offers.AllEntityFeturedOfferDetails);
  let availableCollectibleDetails = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails)?.filter(obj => (!obj?.animationUrl || obj?.animationUrl === "")) ?? [];
  let allPremiumChatDetails = useAppSelector(state => state.Offers.AllEntityPremiumChatDetails) ?? [];

  const customType = (type === CatalogTypes.EXPASSES) ? "ExPass" : type;
  const onboardedEntity = entityDetails?.entityOnboarded;
  // const { wallet } = useWeb3Context()

  useEffect(() => {
    if (isFormForNewPassport) {
      clearOptimisedImage()
      clearImage()
      clearThumbnail()
    }
  }, [isFormForNewPassport])



  const associatedPassports = useMemo(() => {
    if (allTheLinkedCollectionsInChats && allTheLinkedCollectionsInChats?.length) {
      const temp = availablePassportDetails?.filter(i => allTheLinkedCollectionsInChats?.find(x => i.address == x && i.address?.toLowerCase() != collectionData?.linkCollectible[0]?.toLowerCase()));
      return temp
    }
    return []
  }, [allTheLinkedCollectionsInChats, availablePassportDetails])

  // const [isPremium, setIsPremium] = useState<boolean>(false);


  const [showConfirmPopup, setShowConfirmPopup] = useState({ confirm: () => { }, state: false, message: "" });
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleAccordion = () => {
    setExpanded(!expanded)
  }

  const adornmentIcon = (
    <InputAdornment position="start">
      {
        formInput.passportType === PassportType.SUBSCRIPTON || type == CatalogTypes.PREMIUM_CHAT || type === CatalogTypes.FEATUREDOFFERS ?
          <img src={Loot8} height={20} width={20}></img>
          :
          <span>$&nbsp;</span>
      }
    </InputAdornment>)


  const imageChangeHandler = async (event: any, imageType?: imageType) => {

    let { files } = event.target;
    let gifThumbnail = null
    if (!files) {
      files = event.dataTransfer.files;
    }
    if (files && files[0]) {
      if (IMAGE_REGEX.test(files[0].type.toString()) && files[0]?.type != "image/gif") {
        if (imageType == 'thumbnail') {
          setThumbnailImage(URL.createObjectURL(files[0]));
        } else if (imageType == 'optimised') {
          setOptimizedImage(URL.createObjectURL(files[0]))
        } else {
          setBackgroundImage(URL.createObjectURL(files[0]));
        }
        setFormInput({
          ...formInput, isVideoCollectible: false, video: "",
          imageProps: {
            ...formInput?.imageProps,
            ...(imageType == 'thumbnail' ? {
              thumbnailImage: files[0],
              thumbnailImageSize: files[0].size
            } : imageType == 'optimised' ? { optimizedImage: files[0], optimizedImageSize: files[0].size } : { image: files[0], imageSize: files[0].size })
          }
        });
      } else if (files[0]?.type == "image/gif" && (imageType == 'original' || imageType == 'optimised')) {
        if (imageType == 'optimised') {
          setOptimizedImage(URL.createObjectURL(files[0]))
        } else {
          setBackgroundImage(URL.createObjectURL(files[0]));
        }

        const thumbnailImage = await compressImage(files[0], 300, 5);
        // gifThumbnail = await getFirstFrameDataUrl(URL.createObjectURL(files[0]),canvasRef)
        if (formInput?.imageProps?.thumbnailImage == "")
          setThumbnailImage(URL.createObjectURL(thumbnailImage));

        setFormInput({
          ...formInput, isVideoCollectible: false, video: "",
          imageProps: {
            ...formInput?.imageProps,
            ...((type == CatalogTypes.EXPASSES || type == CatalogTypes.COLLECTIBLES)
              && (formInput?.imageProps?.thumbnailImage == "") ? {
              thumbnailImage: thumbnailImage,
              thumbnailImageSize: thumbnailImage?.size,
            } : {}),
            ...(imageType == 'optimised' ? {
              optimizedImage: files[0], optimizedImageSize: files[0].size
            } : {
              image: files[0], imageSize: files[0].size
            })

          }
        })


      }
      else if (VIDEO_REGEX.test(files[0].type.toString())) {
        let thumbnail = await getThumbnailFromVideo(files[0]);
        setFormInput({
          ...formInput, video: files[0], isVideoCollectible: true,
          imageProps: {
            ...formInput?.imageProps,
            image: thumbnail, imageSize: 0
          }
        });
        setBackgroundVideo(URL.createObjectURL(files[0]));
        setBackgroundImage(URL.createObjectURL(thumbnail));
      }
    }
  }

  const nameChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, name: text });
  }

  const animationUrlChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, animationUrl: text });
  }

  const descriptionChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, details: text });
  }

  const priceChangeHandler = (price) => {
    const formData = { ...formInput };
    formData.price = price;
    if (formInput.passportType == PassportType.SUBSCRIPTON && Number(formInput?.price) !== 0) {

      formData.start = null;
      formData.end = null;
      formData.area = { latitude: "", longitude: "", radius: 0 };

    }
    setFormInput({ ...formInput, ...formData });

  }

  const networkChangeHandler = (event) => {
    setFormInput({ ...formInput, chain: event.target.value });
  }

  const startDateChangeHandler = (date) => {
    let changedDate = null;
    if (date) {
      changedDate = new Date(date);
    }
    setFormInput({ ...formInput, start: changedDate });
  }

  const endDateChangedHandler = (date) => {
    let changedDate = null;
    if (date) {
      changedDate = new Date(date);
    }
    setFormInput({ ...formInput, end: changedDate });
  }

  const handleCollaborators = (item) => {
    if(batchLimit.collaborators==5){
      setShowToastError({ open: true, message: `Only 5 collaborators can be added at a time.` });
      return 
    }
    const currentCollaborators = formInput.collaborators.map(collaborator => collaborator.toLowerCase());
    if (currentCollaborators.includes(item.toLowerCase())) {
      const filteredCollaborators = formInput.collaborators.filter(collaborator =>
        collaborator.toLowerCase() !== item.toLowerCase()
      );
      setBatchLimit({ ...batchLimit, collaborators: batchLimit.collaborators - 1 })

      setFormInput({ ...formInput, collaborators: filteredCollaborators });
    } else {
      setFormInput({ ...formInput, collaborators: [...formInput.collaborators, item] });
      setBatchLimit({ ...batchLimit, collaborators: batchLimit.collaborators + 1 })

    }
  };

  const handleGateKeepers = (item) => {
    if(batchLimit.gatekeeper==5){
      setShowToastError({ open: true, message: `Only 5 gatekeepers can be added at a time.` });
      return 
    }
    const currentCollaborators = formInput.getKeepers.map(collaborator => collaborator.toLowerCase());
    if (currentCollaborators.includes(item.toLowerCase())) {
      const filteredCollaborators = formInput.getKeepers.filter(collaborator =>
        collaborator.toLowerCase() !== item.toLowerCase()
      );
      setBatchLimit({ ...batchLimit, gatekeeper: batchLimit.gatekeeper - 1 })
      setFormInput({ ...formInput, getKeepers: filteredCollaborators });
    } else {
      setBatchLimit({ ...batchLimit, gatekeeper: batchLimit.gatekeeper + 1 })
      setFormInput({ ...formInput, getKeepers: [...formInput.getKeepers, item] });
    }
  };


  const handleLinkedFeaturedOffers = (events:any) => {
    let collectibles = events.target.value;
    let newCollectibles = collectibles?.filter(item => !collectionData.linkedTicketFeaturedOffers.includes(item));
    if (newCollectibles.length <= 10) {
      setFormInput({ ...formInput, linkedTicketFeaturedOffers: collectibles });
    }
    else {
      setShowToastError({ open: true, message: `Can be associated to maximum 10 Collections at a time` });
    }
  }

  const handleLinkedCollectibles = (events:any) => {
    let collectibles = events.target.value;
    let newCollectibles = collectibles?.filter(item => !collectionData.linkedTicketCollectibles.includes(item));
    if (newCollectibles.length <= 10) {
      setFormInput({ ...formInput, linkedTicketCollectibles: collectibles });
    }
    else {
      setShowToastError({ open: true, message: `Can be associated to maximum 10 Collections at a time` });
    }
  }

  const linkcollectionChangedHandler = (events: any) => {
    let passports = events.target.value;
    if (type == CatalogTypes.PREMIUM_CHAT) {

      let singleCollectible = [];
      singleCollectible.push(passports)
      setFormInput({ ...formInput, linkCollectible: singleCollectible });
      return
    }
    let newPassports = passports?.filter(item => !collectionData.linkCollectible.includes(item));
    if (newPassports.length <= 10) {
      setFormInput({ ...formInput, linkCollectible: passports });
    }
    else {
      setShowToastError({ open: true, message: `Can be associated to maximum 10 Collections at a time` });
    }
  }

  const gpsChangeHandler = (events) => {
    let checked = events.target.checked;
    setGpsChecked(checked);
    if (!checked) {
      setFormInput({ ...formInput, area: { latitude: "", longitude: "", radius: 0 } });
    }
    else {
      setFormInput({ ...formInput, area: collectionData.area });
    }
  }

  const onLatitudeChangeHandler = (events: any) => {
    let latitude = events.target.value
    setFormInput({ ...formInput, area: { ...formInput.area, latitude: latitude } });
  }

  const onLongitudeChangeHandler = (events: any) => {
    let longitude = events.target.value
    setFormInput({ ...formInput, area: { ...formInput.area, longitude: longitude } });
  }

  const onRadiusChangeHandler = (value) => {
    setFormInput({ ...formInput, area: { ...formInput.area, radius: Number(value) } });
  }

  const limitedCountCheckHandler = (events) => {
    let checked = events.target.checked;
    setLimitedCountChecked(checked);
    if (!checked) {
      setFormInput({ ...formInput, maxMint: 0, maxPurchase: 0 });
    }
    else {
      setFormInput({ ...formInput, maxMint: collectionData.maxMint, maxPurchase: collectionData.maxPurchase });
    }
  }

  const limitedCountChangeHandler = (value) => {
    setFormInput({ ...formInput, maxMint: Number(value), maxPurchase: Number(value) });
  }

  const handleMaxPurchase = (value) => {
    setFormInput({ ...formInput, maxPurchase: Number(value) })
  }


  const handlePriceRate = (value) => {
    setFormInput({ ...formInput, priceRate: Number(value?.target?.value) < 100 ? Number(value?.target?.value) : 100 })
  }

  const handlePrivateMaxCap = (value) => {
    setFormInput({ ...formInput, privateMessageCap: String(value) == "0" ? 1 : Number(value) })
  }



  const tradeabilityCheckHandler = (events) => {
    let checked: boolean = events.target.checked;
    setFormInput({
      ...formInput, marketPlaceConfig: {
        allowMarketplaceOps: formInput?.marketPlaceConfig?.allowMarketplaceOps,
        privateTradeAllowed: checked,
        publicTradeAllowed: checked,
      }
    });
  }

  const maxBalanceCheckHandler = (events) => {
    let checked = events.target.checked;
    setMaxBalanceChecked(checked);
    if (!checked) {
      setFormInput({ ...formInput, maxBalance: 0 });
    }
    else {
      setFormInput({ ...formInput, maxBalance: collectionData.maxBalance });
    }
  }
  const maxBalanceChangeHandler = (value) => {
    setFormInput({ ...formInput, maxBalance: Number(value) });
  }
  const socialLinkChangeHandler = (events) => {
    let checked = events.target.checked;
    if (checked) {
      setFormInput({ ...formInput, socialLinks: collectionData.socialLinks ?? { instagram: "" } });
    }
    else {
      setFormInput({ ...formInput, socialLinks: null });
    }
  }

  const onAddSocialLinkHandler = (social) => {
    setFormInput({ ...formInput, socialLinks: { ...formInput.socialLinks, [social]: "" } });
  }

  const onRemoveSocialLinkHandler = (social) => {
    const formSocialLinks = formInput?.socialLinks;
    delete formSocialLinks[social];
    setFormInput({ ...formInput, socialLinks: formSocialLinks });

  }

  const urlChangeHandler = (event, account: "facebook" | "telegram" | "discord" | "twitter" | "instagram" | "tiktok") => {
    const { value } = event.target;
    setFormInput({ ...formInput, socialLinks: { ...formInput.socialLinks, [account]: value } })
  }



  const mintWithLinkedChangeHandler = (events) => {
    let checked = events.target.checked;
    setFormInput({ ...formInput, mintWithLinkedOnly: checked });
  }

  const couponChangeHandler = (events) => {
    let checked = events.target.checked;
    setFormInput({ ...formInput, isCoupon: checked, maxMint: 0, maxPurchase: 0 });
    if (!checked) {
      setTierCollection("");
      setMaxTokens(0);
    }
  }

  const tierCollectionChangeHandler = (address) => {
    setTierCollection(address);
  }

  const maxTokenChangeHandler = (value) => {
    setMaxTokens(Number(value));
    setFormInput({ ...formInput, maxMint: Number(value), maxPurchase: 0 });
  }


  const clearImage = () => {
    setBackgroundImage('');
    setBackgroundVideo('');
    setFormInput({
      ...formInput,
      imageProps: {
        ...formInput?.imageProps,
        image: "", imageSize: 0
      }
    })
  }
  const clearThumbnail = () => {
    setThumbnailImage('')
    setFormInput({
      ...formInput,
      imageProps: {
        ...formInput?.imageProps,
        thumbnailImage: "",
        thumbnailImageSize: 0
      }
    })

  }
  const clearOptimisedImage = () => {
    setOptimizedImage('')
    setFormInput({
      ...formInput,
      imageProps: {
        ...formInput?.imageProps,
        optimizedImage: '', optimizedImageSize: 0
      }
    })
  }


  const onModalCancel = () => {
    setShowConfirmPopup({ confirm: () => { }, state: false, message: "" });
  }

  // const socialLinksCount = useMemo(() => {
  //   let count = 0;
  //   if (formInput.socialLinks) {
  //     const { facebook, instagram, twitter, tiktok, discord, telegram } = formInput.socialLinks;
  //     if (facebook != "") {
  //       count++
  //     } if (instagram != "") {
  //       count++
  //     } if (tiktok != "") {
  //       count++
  //     } if (twitter != "") {
  //       count++
  //     } if (telegram != "") {
  //       count++
  //     } if (discord != "") {
  //       count++
  //     }
  //   }
  //   return count
  // }, [formInput])

  const socialLinks = useMemo(() => {
    if (formInput)
      return [
        {
          id: 1,
          label: 'Discord',
          icon: discord,
          handler: (event) => urlChangeHandler(event, "discord"),
          disabled: !formInput?.isActive,
          pattern: DiscordRegex,
          required: formInput?.socialLinks?.discord !== "",
          placeHolder: "Add Link",
          value: formInput?.socialLinks?.discord ?? "",
          key: 'discord'
        },
        {
          id: 2,
          label: 'Telegram',
          icon: telegram,
          handler: (event) => urlChangeHandler(event, "telegram"),
          disabled: !formInput?.isActive,
          pattern: TelegramRegex,
          required: formInput?.socialLinks?.telegram !== "",
          placeHolder: "Add Link",
          value: formInput?.socialLinks?.telegram ?? "",
          key: 'telegram'

        },
        {
          id: 3,
          label: 'Facebook',
          icon: facebook,
          handler: (event) => urlChangeHandler(event, "facebook"),
          disabled: !formInput?.isActive,
          pattern: FacebookRegex,
          required: formInput?.socialLinks?.facebook !== "",
          placeHolder: "Add Link",
          value: formInput?.socialLinks?.facebook ?? "",
          key: 'facebook'

        },
        {
          id: 4,
          label: 'Instagram',
          icon: instagram,
          handler: (event) => urlChangeHandler(event, "instagram"),
          disabled: !formInput?.isActive,
          pattern: InstagramRegex,
          required: formInput?.socialLinks?.instagram !== "",
          placeHolder: "Add Link",
          value: formInput?.socialLinks?.instagram ?? "",
          key: 'instagram'

        },
        {
          id: 5, label: 'TikTok', icon: tiktok,
          handler: (event) => urlChangeHandler(event, "tiktok"),
          disabled: !formInput?.isActive,
          pattern: TikTokRegex,
          required: formInput?.socialLinks?.tiktok !== "",
          placeHolder: "Add Link",
          value: formInput?.socialLinks?.tiktok ?? "",
          key: 'tiktok'

        },
        {
          id: 6, label: 'Twitter', icon: twitter,
          handler: (event) => urlChangeHandler(event, "twitter"),
          disabled: !formInput?.isActive,
          pattern: TwitterRegex,
          required: formInput?.socialLinks?.twitter !== "",
          placeHolder: "Add Link",
          value: formInput?.socialLinks?.twitter ?? "",
          key: 'twitter'

        }
      ]
  }, [formInput])

  const handleRemove = () => {
    setFormInput({ ...formInput, linkCollectible: [] })
  }


  const disableLinking = useMemo(() => {
    if ((((!formInput?.isActive && formInput?.linkCollectible?.length) || (formInput?.isActive)) && type == CatalogTypes?.PREMIUM_CHAT)) {
      return false
    } else if (formInput?.isActive && type != CatalogTypes?.PREMIUM_CHAT) {
      return false
    }
    return true
  }, [formInput, type])


  const handlePremium = () => {
    setFormInput({ ...formInput, isPremium: !formInput?.isPremium })
  }

  return (
    <>
      {/* <Box sx={{width: "100%", justifyContent: 'center', alignSelf: 'center', display: 'flex',flexDirection:'row' }}> */}
      <form id='collection-form' onSubmit={formInput.address === "" ? onCreationSwitch : onSubmitHandler}>
        {/* <Content> */}

        <Grid container display={'flex'} columnGap={4}>
          {/* <div id='modal-walkthrough'> */}
          <Grid className='modal-walkthrough' item xl={4} lg={4} sm={12} xs={12}>
            <NameTextInput
              label={`${customType} Name`}
              placeholder={`Name your ${customType}`}
              name={formInput.name}
              nameChangeHandler={nameChangeHandler}
              required={true}
              disabled={formInput?.address != "" ? true : false}
            />

            <DescriptionTextInput
              description={formInput.details}
              edit={formInput?.address != "" ? true : false}
              descriptionChangeHandler={descriptionChangeHandler}
              required={false}
              disabled={!formInput?.isActive}
            />

            {type != CatalogTypes.COLLECTIBLES && type != CatalogTypes.EVENT &&
              <>
                <PriceInput
                  label={"Price"}
                  placeholder={'Fees'}
                  price={formInput.price}
                  priceChangeHandler={priceChangeHandler}
                  required={(formInput.passportType === PassportType.SUBSCRIPTON || collectionData.offerType == OfferType.FEATURED)}
                  disabled={(!formInput?.isActive ||
                    (formInput.passportType === PassportType.SUBSCRIPTON && formInput?.subscriptionSet) ||
                    ((type == CatalogTypes.PREMIUM_CHAT || collectionData.offerType == OfferType.FEATURED) && formInput?.address != ''))}
                  adornment={adornmentIcon}
                />
                {(formInput.passportType === PassportType.SUBSCRIPTON || collectionData.offerType == OfferType.FEATURED) &&
                  <Box display={'flex'} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                    <Typography variant="caption" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins'}>
                      Users to pay {(Number(formInput?.price) + (Number(formInput?.price) * 0.2)).toFixed(2)} (+20%) Loot8 tokens
                    </Typography>
                    <LightTooltip title={"Kindly be aware that the total cost for users will amount to +20% loot8 tokens, encompassing a 10% loot8 tokens as subscription fee allocated to you and another 10% loot8 tokens directed to Loot8."} placement="bottom" arrow>
                      <InfoRoundedIcon sx={{ fontSize: '1rem' }} color='disabled' />
                    </LightTooltip>
                  </Box>
                }
              </>
            }
            {type == CatalogTypes.EXPASSES ?
              <>
                <BasicTextInput placeholder='Enter percentage' required={true} disabled={formInput?.address == "" ? false : true} valueChangeHandler={handlePriceRate} label='Percentage' value={formInput?.priceRate} />
                <MyLineChart price={formInput?.price} percentage={formInput?.priceRate} />
              </> : <></>
            }
            <Box mt={1} />
            {type == CatalogTypes.EVENT &&
              <CollaboratorDropDown
                isOwner={wallet?.address?.toLowerCase() == formInput?.owner?.toLowerCase() || formInput?.address == ""}
                selectedCollaborators={formInput?.getKeepers} handleSelection={handleGateKeepers} placeholder='Add Gatekeepers' allUserData={allUserData} label='Gatekeeper Name QR Scanner' />}
            {type != CatalogTypes.PREMIUM_CHAT && formInput.passportType != PassportType.SUBSCRIPTON ?
              <Grid display={'flex'} columnGap={2} flexDirection={'row'} container >
                {/* <BasicTextInput
                  value={customType}
                  valueChangeHandler={() => { }}
                  label={'Type'}
                  placeholder={""}
                  required={false}
                  disabled={true}
                /> */}
                {!(type === CatalogTypes.PASSPORT || type === CatalogTypes.FEATUREDOFFERS) &&
                  <Grid item lg={12} xl={12}>
                    <NetworkSelect
                      handleChange={networkChangeHandler}
                      selectedChain={formInput.chain}
                      disabled={formInput.address !== ""}
                      required={true} />
                  </Grid>
                }

              </Grid> : <></>}

            {!(type === CatalogTypes.PREMIUM_CHAT || (formInput.passportType == PassportType.SUBSCRIPTON && Number(formInput?.price) !== 0) || collectionData.offerType == OfferType.FEATURED) || type==CatalogTypes.EVENT ?
              type != CatalogTypes?.PRIVATE_CHAT ?
                <div style={{
                  flex: 1,
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 10
                }}>
                  <div style={{ width: '48%' }}>
                    <DatePickerComponent
                      label={"Start Date"}
                      selectedDate={formInput.start}
                      datechangedHandler={startDateChangeHandler}
                      disabled={!formInput?.isActive} />
                  </div>
                  <div style={{ width: '48%' }}>
                    <DatePickerComponent
                      label={"End Date"}
                      selectedDate={formInput.end}
                      datechangedHandler={endDateChangedHandler}
                      disabled={!formInput?.isActive} />
                  </div>
                </div> : <></> : <></>
            }

            {/* {(type === CatalogTypes.COLLECTIBLES) &&
              <BasicTextInput value={formInput.animationUrl ?? ""} valueChangeHandler={animationUrlChangeHandler} label={'Animation URL'} placeholder={"Enter a animation URL"} required={false} disabled={!formInput?.isActive} />

            } */}


            {/* {!onboardedEntity || type == CatalogTypes.PREMIUM_CHAT ? */}
            
            {type==CatalogTypes.EVENT && formInput?.eventTickets?.length && 
                <SelectInput
                handleRemove={handleRemove}
                allowRemove={(type == CatalogTypes.PREMIUM_CHAT && formInput?.linkCollectible?.length)}
                alreadyLinkedPassport={associatedPassports}
                multiple={true}
                label={"Associated Featured Offers" }
                menuData={availbleFeaturedOffers?.filter(i=>i.isActive)}
                handleOptionChange={handleLinkedFeaturedOffers}
                selectedOptions={singleCollectible ? singleCollectible : formInput.linkedTicketFeaturedOffers}
                disabled={disableLinking}
                required={(formInput?.address == "" && type == CatalogTypes.PREMIUM_CHAT)}
              />
             }
             {type==CatalogTypes.EVENT && formInput?.eventTickets?.length && 
                <SelectInput
                handleRemove={handleRemove}
                allowRemove={(type == CatalogTypes.PREMIUM_CHAT && formInput?.linkCollectible?.length)}
                alreadyLinkedPassport={associatedPassports}
                multiple={true}
                label={"Associated Collectibles" }
                menuData={availableCollectibleDetails?.filter(i=>i.isActive)}
                handleOptionChange={handleLinkedCollectibles}
                selectedOptions={singleCollectible ? singleCollectible : formInput?.linkedTicketCollectibles}
                disabled={disableLinking}
                required={(formInput?.address == "" && type == CatalogTypes.PREMIUM_CHAT)}
              />
             }
            <SelectInput
              handleRemove={handleRemove}
              allowRemove={(type == CatalogTypes.PREMIUM_CHAT && formInput?.linkCollectible?.length)}
              alreadyLinkedPassport={associatedPassports}
              multiple={type == CatalogTypes.PREMIUM_CHAT ? false : true}
              label={collectionData.collectionType === CollectionType.PASSPORT ? "Associated Collectibles" : "Associated Passports"}
              menuData={collectionData.collectionType === CollectionType.PASSPORT ? availableCollectibleDetails :
                entityDetails?.entityOnboarded ? (type == CatalogTypes.FEATUREDOFFERS || type == CatalogTypes.ANNOUNCEMENT || type == CatalogTypes.COLLECTIBLES || type == CatalogTypes.EVENT) ?
                  availablePassportDetails.filter(i => i.isActive) : availablePassportDetails.filter(i => i.isPremium && i.isActive) : availablePassportDetails.filter(i => i.isActive)}
              handleOptionChange={linkcollectionChangedHandler}
              selectedOptions={singleCollectible ? singleCollectible : formInput.linkCollectible}
              disabled={disableLinking}
              required={(formInput?.address == "" && type == CatalogTypes.PREMIUM_CHAT)}
            />
            {/* : <></>} */}
          </Grid>
          {/* </div> */}
          <Grid className='modal-image-walkthrough' item xl={3} lg={3} sm={12} xs={12}>
            <ImageCard label={'Upload Image'}
              clear={clearImage}
              isPassport
              catalogType={CatalogTypes.EXPASSES}
              subHeading='Max file size - 30 MB. This image/gif will be visible in the details screen in the user app.'
              imageType={'original'}
              background={formInput.isVideoCollectible ? backgroundVideo : backgroundImage}
              backgroundImageChangeHandler={imageChangeHandler}
              fileTypes={type === CatalogTypes.COLLECTIBLES ? "image/jpg, image/jpeg, image/png, video/*" : "image/jpg, image/jpeg, image/png, image/gif"}
              isVideo={formInput.isVideoCollectible}
              isDisabled={!formInput?.isActive} />

            {type == CatalogTypes.EXPASSES || type == CatalogTypes.COLLECTIBLES || type == CatalogTypes.EVENT ?
              <ImageCard isPassport label={'Upload Thumbnail Image'}
                clear={clearThumbnail}
                catalogType={CatalogTypes.EXPASSES}
                subHeading='Max file size - 1 MB. This image will be visible in the list pages with tiles view in the user app.'
                imageType={'thumbnail'}
                background={formInput.isVideoCollectible ? backgroundVideo : thumbnailImage}
                backgroundImageChangeHandler={imageChangeHandler}
                fileTypes={"image/jpg, image/jpeg, image/png"}
                isVideo={formInput.isVideoCollectible}
                isDisabled={!formInput?.isActive} />
              : <></>}

            {type == CatalogTypes.EXPASSES || type == CatalogTypes.COLLECTIBLES || type == CatalogTypes.EVENT ?
              <ImageCard
                isPassport
                label={'Upload Optimised Image'}
                catalogType={type}
                subHeading='Max  file size - 2 MB. This image/gif will be visible in the list pages with card view in the user app.'
                clear={clearOptimisedImage}
                imageType='optimised'
                background={formInput.isVideoCollectible ? backgroundVideo : optimizedImage}
                backgroundImageChangeHandler={imageChangeHandler}
                fileTypes={"image/jpg, image/jpeg, image/png"}
                isVideo={formInput.isVideoCollectible}
                isDisabled={!formInput?.isActive} /> : <></>}
          </Grid>

          <Grid item xl={4} lg={4} sm={12} xs={12}>
            {/* <Grid item xl={4} lg={4} sm={12} xs={12}> */}




            {/* <Box>
                <CustomCheckbox label={`Limited ${type==CatalogTypes.PREMIUM_CHAT ? "Participants" :customType}`} checked={!formInput.isCoupon && limitedCountChecked} handleCheckChange={limitedCountCheckHandler} disabled={formInput.isCoupon || !formInput?.isActive || formInput.address !== ""}/>
                { limitedCountChecked && !formInput.isCoupon && <CountInput label={type==CatalogTypes.PREMIUM_CHAT ? "Maximum Number of People" : `Maximum ${customType} Offered`} count={formInput.maxMint} countChangeHandler={limitedCountChangeHandler} required={limitedCountChecked} disabled={!formInput?.isActive || formInput.address !== ""}/> }
              </Box> */}

            {/* { ((formInput.passportType === PassportType.SUBSCRIPTON) || (formInput.address !== "" && (type === CatalogTypes.PASSPORT || type === CatalogTypes.COLLECTIBLES || type==CatalogTypes.PREMIUM_CHAT))) &&
              <div>
                <CustomCheckbox label={`Tradable in Marketplace`} checked={formInput?.marketPlaceConfig?.publicTradeAllowed} handleCheckChange={tradeabilityCheckHandler} disabled={!formInput?.isActive || !formInput?.marketPlaceConfig.allowMarketplaceOps}/>
                <LightTooltip title={infoText + (!formInput?.marketPlaceConfig?.allowMarketplaceOps ? " Please reach out to Loot8 to whitelist the collection for enabling tradability options." : "")} placement="bottom" arrow>
                  <InfoRoundedIcon className='mt-2' fontSize='small' sx={{ color: '#FFFFFF'}} />
                </LightTooltip>
              </div>
            } */}




            <Accordion
              className='modal-advanced-settings'
              sx={{ backgroundColor: 'transparent', marginTop: '10px' }}
              elevation={0}
              disableGutters
              expanded={expanded}
              onChange={handleAccordion}
            >

              <AccordionSummary
                sx={{ margin: 0, borderBottom: '1px solid #70707070', }}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant="body2"
                  color={defaultTheme.buttonDarkTextColor}
                  fontFamily={'Poppins-Medium'}>
                  Advanced Settings
                </Typography>
              </AccordionSummary>

              <AccordionDetails>

                {!onboardedEntity && type != CatalogTypes.EVENT && <>
                  {
                    !((collectionData.collectionType === CollectionType.PASSPORT) || (type === CatalogTypes.COLLECTIBLES) || (type == CatalogTypes.PREMIUM_CHAT) || (type == CatalogTypes?.PRIVATE_CHAT) || type === CatalogTypes.FEATUREDOFFERS) &&
                    <>
                      <CustomCheckbox label={"Max Balance"} checked={maxBalanceChecked} handleCheckChange={maxBalanceCheckHandler} disabled={!formInput?.isActive} />
                      {maxBalanceChecked && <CountInput label={"Maximum NFT mint for a single user"} count={formInput.maxBalance} countChangeHandler={maxBalanceChangeHandler} required={maxBalanceChecked} disabled={!formInput?.isActive} />}
                      <br />
                    </>
                  }



                  {/* {((type === CatalogTypes.OFFERS) || (type === CatalogTypes.FEATUREDOFFERS)) && <>

                <CustomCheckbox label={"Enable Redeem Function"} checked={formInput.isCoupon} handleCheckChange={couponChangeHandler} disabled={(formInput.address !== "")} />
                {
                  formInput.isCoupon &&
                  <>
                    <AddressInput label={"POAP Address"} value={tierCollection} setTextValue={tierCollectionChangeHandler} required={formInput.isCoupon} disabled={(formInput.address !== "")} />
                    <CountInput label={"Max Tokens"} count={maxTokens} countChangeHandler={maxTokenChangeHandler} required={formInput.isCoupon} disabled={(formInput.address !== "")} />
                  </>
                }
              </>
              } */}

                </>}

                {(type === CatalogTypes.EXPASSES || type === CatalogTypes.COLLECTIBLES || type == CatalogTypes.PREMIUM_CHAT || type === CatalogTypes.FEATUREDOFFERS) &&
                  <div>
                    <CustomCheckbox
                      label={`Tradable in Marketplace`}
                      checked={formInput?.marketPlaceConfig?.publicTradeAllowed}
                      handleCheckChange={tradeabilityCheckHandler}
                      disabled={!collectionData?.isActive || !collectionData?.marketPlaceConfig?.allowMarketplaceOps || (collectionData.address !== "" && collectionData?.marketPlaceConfig?.publicTradeAllowed)} />

                    <LightTooltip title={infoText + (!formInput?.marketPlaceConfig?.allowMarketplaceOps ? " Please reach out to Loot8 to whitelist the collection for enabling tradability options." : "")} placement="bottom" arrow>
                      <InfoRoundedIcon className='mt-2' fontSize='small' color='disabled' />
                    </LightTooltip>
                  </div>
                }
                {type == CatalogTypes.PRIVATE_CHAT ?
                  <CountInput label={"Private message cap"} count={formInput.privateMessageCap} countChangeHandler={handlePrivateMaxCap} required={true} disabled={!formInput?.isActive || formInput.address !== ""} /> : <></>}

                {type != CatalogTypes.EVENT &&
                  <Grid item xl={12} lg={12} sm={12} md={12} xs={12} >
                    <Grid item xl={type == CatalogTypes.PREMIUM_CHAT ? 12 : 12} lg={type == CatalogTypes.PREMIUM_CHAT ? 12 : 12}>
                      <CustomCheckbox label={`Limited ${type == CatalogTypes.PREMIUM_CHAT ? "Participants" : customType}`} checked={!formInput.isCoupon && limitedCountChecked} handleCheckChange={limitedCountCheckHandler} disabled={formInput.isCoupon || !formInput?.isActive || formInput.address !== ""} />
                      <LightTooltip title={"Setting a limit will result in the subscription being closed when the specified number is reached."} placement="bottom" arrow>
                        <InfoRoundedIcon className='mt-2' fontSize='small' color='disabled' />
                      </LightTooltip>
                      {limitedCountChecked && !formInput.isCoupon &&
                        <CountInput label={type == CatalogTypes.PREMIUM_CHAT ? "Maximum Number of People" : `Maximum ${customType} Offered`} count={formInput.maxMint} countChangeHandler={limitedCountChangeHandler} required={limitedCountChecked} disabled={!formInput?.isActive || formInput.address !== ""} />}

                    </Grid>
                    {!onboardedEntity ?
                      <Grid item xl={10} lg={10}>
                        {
                          (type == CatalogTypes.EXPASSES && (Number(formInput?.price) === 0)) &&
                          <CustomCheckbox label={"Require Mint with linked"} checked={formInput.mintWithLinkedOnly} handleCheckChange={mintWithLinkedChangeHandler} disabled={!formInput?.isActive} />
                        }
                      </Grid> : <></>}
                  </Grid>}

                {/* {type == CatalogTypes.EVENT &&
                  <Grid item display={'column'} alignItems={'center'} lg={12} xl={12} sm={12}>
                    <CustomCheckbox label={`Invite ExPass Collaborators`} checked={formInput?.inviteCollaborators}
                      handleCheckChange={() => setFormInput({ ...formInput, inviteCollaborators: !formInput?.inviteCollaborators })} disabled={false} />
                    {formInput?.inviteCollaborators &&
                      <CollaboratorDropDown

                        selectedCollaborators={formInput?.collaborators}
                        isOwner={wallet?.address?.toLowerCase() == formInput?.owner?.toLowerCase() || formInput?.address == ""}
                        allUserData={allUserData} handleSelection={handleCollaborators} placeholder="Invite collaborators" />}

                  </Grid>} */}

                <Grid item display={'flex'} alignItems={'center'} lg={12} xl={12} sm={12}>
                  {
                    (collectionData.collectionType === CollectionType.PASSPORT) &&
                    <SocialLinksComponent formSocialLinks={formInput.socialLinks}
                      onAddSocialLinkHandler={onAddSocialLinkHandler}
                      onRemoveSocialLinkHandler={onRemoveSocialLinkHandler}
                      socialLinks={socialLinks}
                      checked={formInput.socialLinks ? true : false}
                      socialLinkChangeHandler={socialLinkChangeHandler} />
                  }
                </Grid>
                {type == CatalogTypes?.EXPASSES &&
                  <Grid item display={'flex'} alignItems={'center'} lg={12} xl={12} sm={12}>

                    {
                      ((wallet?.address?.toLowerCase() == "0xBE92347869355689982d55C428d6b90084a851a6".toLowerCase() ||
                        wallet?.address?.toLowerCase() == "0x11eD055D196455e31342399262f7f2F5F04eB290".toLowerCase() ||
                        wallet?.address?.toLowerCase() == "0x273ebB35b82444ec3d6dE7a8B6559c8a131572Fe".toLowerCase() ||
                        wallet?.address?.toLowerCase() == "0xE35bE4Bd273D4963fD2FE2FAfE55F937C2d378a6".toLowerCase()
                      ) && isProduction) || !isProduction &&
                      (type == CatalogTypes?.EXPASSES) &&
                      <Grid item display={'flex'} alignItems={'center'} lg={12} xl={12} sm={12}>
                        <CustomCheckbox label={"is Premium"} checked={formInput?.isPremium} handleCheckChange={handlePremium} disabled={!formInput?.isActive} />
                      </Grid>}


                  </Grid>}
                {

                  type == CatalogTypes?.EXPASSES &&
                  <>
                    <Typography variant="body2" className="mt-3" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
                      {"Select Category"}
                    </Typography>
                    <Select
                      required={true}
                      value={typeof formInput?.category == "string" ? formInput?.category : Categories.find(i => i.enum == formInput?.category)?.value}
                      className='input-select-passport-value w-100 '

                      placeholder="Select Category"
                      onChange={(event) => {
                        setCategory(CATEGORIES[event?.target?.value])
                        setFormInput({ ...formInput, category: event?.target?.value })
                      }}
                      sx={{
                        mt: 1,
                        backgroundColor: defaultTheme.newFieldColor,
                        color: defaultTheme.buttonDarkTextColor,
                        fontFamily: 'Poppins-Medium',
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: 'none',
                        },
                        [`& .${outlinedInputClasses.disabled}`]: {
                          WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
                          cursor: 'not-allowed'
                        }
                      }}
                      SelectDisplayProps={{
                        style: {
                          paddingBlock: 8.5,
                          maxWidth: "100%"
                        }
                      }}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            padding: 0,
                            '& .Mui-selected': {
                              backgroundColor: '#a8189030', // Replace with your desired color
                              ':hover': {
                                backgroundColor: '#a818901f'
                              }
                            },
                          },
                        },
                        PaperProps: {
                          sx: {
                            maxWidth: '5rem',
                            maxHeight: '18rem'
                          }
                        },
                      }}
                    >
                      {Categories?.map(i =>
                        <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                      )}

                    </Select>
                  </>}

                {!(type === CatalogTypes.PREMIUM_CHAT || type === CatalogTypes.FEATUREDOFFERS || (formInput.passportType == PassportType.SUBSCRIPTON && Number(formInput?.price) !== 0)) ? <>
                  {type != CatalogTypes?.PRIVATE_CHAT ?
                    <CustomCheckbox label={"GPS"} checked={gpsChecked} handleCheckChange={gpsChangeHandler} disabled={!formInput?.isActive} /> : <></>}
                  {
                    gpsChecked &&
                    <Box display={'flex'} gap={'1rem'} flexDirection={'row'}>
                      <CoordinateInput label={"Latitude"} validator={latRegex} coordinateValue={formInput.area.latitude}
                        onCoordinateChange={onLatitudeChangeHandler} required={gpsChecked} disabled={!formInput?.isActive} />
                      <CoordinateInput label={"Longitude"} validator={lonRegex} coordinateValue={formInput.area.longitude}
                        onCoordinateChange={onLongitudeChangeHandler} required={gpsChecked} disabled={!formInput?.isActive} />
                      <CountInput label={"Radius"} count={formInput.area.radius} countChangeHandler={onRadiusChangeHandler}
                        required={true} disabled={!formInput?.isActive} />
                    </Box>
                  }
                </> : <></>}

              </AccordionDetails>

            </Accordion>

            <div className='my-4' style={{ display: 'flex', justifyContent: 'space-around' }} >
              <CustomButton
                className={"col-5 col-sm-5 col-lg-5"}
                onClick={onModalClose}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.buttonRedColor}
                disabled={false}
                type={"button"}
              >
                CANCEL
              </CustomButton>

              <CustomButton
                className={"col-5 col-sm-5 col-lg-5"}
                // onClick={() => { }}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.yellowColor}
                type={"submit"}
                disabled={JSON.stringify(collectionData) === JSON.stringify(formInput) && !(formInput.address !== "" && !collectionData.subscriptionSet && formInput.passportType === PassportType.SUBSCRIPTON)}
              >
                SAVE
              </CustomButton>
            </div>

          </Grid>
        </Grid>
      </form>
      {
        showConfirmPopup.state && <ConfirmModal modalOpen={showConfirmPopup.state} text={showConfirmPopup.message} onModalCancel={onModalCancel} onModalSubmit={showConfirmPopup.confirm} />
      }
    </>
  );
};
export default EditCollectibleModal