import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { RootState } from "../store";
import { convertAreaData, setAll } from "./helpers";
import { APP_STORAGE_GET_COLLECTIBLEDETAILS, ZERO_ADDRESS, addresses, getAnynetStaticProvider, getNetwork } from "../constants";
import { getAllCollectibleList, getCollectibleDetails, getSocialMediaAccess } from "./PassportSlice";
import { CollectionType } from "../enums/collection.enum";
import { ICollectibleDetail } from "../interfaces/ICollection.interface";
import { CATEGORIES, OfferType, PassportType } from "../enums/offers.enum";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
import { ICollectibleAsyncThunk, ICollectionBaseAsyncThunk, IEventAsyncThunk } from "./interfaces";
import { CollectionHelper__factory, CollectionManager__factory, EventFactory__factory, EventRegistry__factory, Event__factory, Loot8UniformCollection__factory } from "../typechain";
import { storeData } from "../helpers/AppStorage";
import { getIPFSData, getIPFSLink } from "../helpers/ipfs";
import { loadAllEvents } from "./EventSlice";

export interface IOffersSliceData {

  readonly AllEntityFeturedOfferDetails: ICollectibleDetail[];
  // readonly AllEntityRegularOfferDetails : ICollectibleDetail[];
  readonly AllEntityPremiumChatDetails: ICollectibleDetail[];
  // readonly AllEntityEventsDetails: ICollectibleDetail[];
  readonly AllEntityDigitalCollectiblesDetails: ICollectibleDetail[];

  readonly Offersloading: boolean;
  readonly Eventsloading: boolean;
  readonly DigitalCollectibleloading: boolean;
  readonly PremiumChatsLoading: boolean;
}



const initialState: IOffersSliceData = {

  // AllEntityRegularOfferDetails: null,
  AllEntityPremiumChatDetails: null,
  AllEntityFeturedOfferDetails: null,
  // AllEntityEventsDetails: null,
  AllEntityDigitalCollectiblesDetails: null,

  Offersloading: false,
  Eventsloading: false,
  DigitalCollectibleloading: false,
  PremiumChatsLoading: false

};

export const loadAllCollections = createAsyncThunk("offers/loadAllCollections", async ({ networkID, wallet, provider, entityAddress, isCache = true, isSuperAdmin }: any, { dispatch, getState }): Promise<any> => {

  const state = getState() as RootState;

  // if(!state?.Entity?.EntityData?.entityOnboarded) {
  // dispatch(loadAllOffers({ networkID, provider, entityAddress, isCache: isCache }));
  dispatch(loadAllEvents({ networkID, provider, wallet: wallet, entityAddress, isCache: isCache, isSuperAdmin }));
  // }

  await dispatch(loadAllDigitalCollectibles({ networkID, provider, wallet: wallet, entityAddress, isCache: isCache, isSuperAdmin }));

});

// export const loadAllOffers = createAsyncThunk("offers/loadAllOfferList", async ({ networkID, provider, entityAddress, isCache = true }: ICollectionBaseAsyncThunk, { dispatch } ): Promise<any> => {

//   let allOfferDetails = [];
//   let offerAddressList = (await getAllCollectibleList({ networkID, provider, collectibleType : CollectionType.OFFER, entity: entityAddress }));

//   if( offerAddressList && offerAddressList.length > 0 ) {

//     await Promise.all( offerAddressList.filter(offerAddress => offerAddress?.source !== ZERO_ADDRESS ).map(async (offerAddress, _index) => {
//       let offerDetails : ICollectibleDetail = (await getCollectibleDetails(
//         { networkID, provider, collectibleData : offerAddress, index: _index, entityAddress}, 
//         { isCache }
//       ));

//       if(offerDetails && offerDetails?.name !== "")  {
//         switch (offerDetails.offerType) {
//           case OfferType.FEATURED:
//             dispatch(updateFeaturedOfferData(offerDetails));
//             break;
//           case OfferType.REGULAR:
//             dispatch(updateRegularOfferData(offerDetails));
//             break;
//         }
//         allOfferDetails.push(offerDetails);
//       }
//     }));
//   }
//   if(allOfferDetails && allOfferDetails.length === 0) {
//     dispatch(updateFeaturedOfferData(null));
//     dispatch(updateRegularOfferData(null));
//   }

//   LogToConsole("all Offers",allOfferDetails);

// });

// export const loadAllEvents = createAsyncThunk("offers/loadAllEventist", async ({ networkID, provider, wallet, entityAddress, isCache = true }: ICollectionBaseAsyncThunk, { dispatch }): Promise<any> => {

//   let allEventDetails = [];
//   let eventAddressList = [];

//   const EventRegistryContract = EventRegistry__factory.connect(addresses[networkID].EventRegistry, provider);
//   const collectionHelper = CollectionHelper__factory.connect(addresses[networkID].CollectionHelper, provider);
//   const CollectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
//   eventAddressList = await EventRegistryContract.getAllEvents(entityAddress);


//   if (eventAddressList?.length) {
//     eventAddressList.map(async (address, index) => {
//       const EventFactory = Event__factory.connect(address, provider);
//       const details = await EventFactory.eventData();
//       const area = await EventFactory.getEventArea();
//       const radius = parseInt(area?.radius?._hex, 16);
//       let coordinates;
//       if (area?.points?.length == 2) {
//         coordinates = area?.points
//       } else {
//         coordinates = [area?.points[0]?.split(',')[0], area?.points[0]?.split(',')[1]]
//       }


//       const linkedCollections = await collectionHelper.getLinkedCollections(address);
//       const areaCoordinates = convertAreaData(coordinates, Number(radius));

//       const allCollaborators = await EventFactory.getAllCollaborators(true);
//       const allGatekeepers = await EventFactory.getAllGatekeepers(true);
//       const owner = await EventFactory.owner();
//       const startDate = parseInt(details?.start?._hex, 16);
//       const endDate = parseInt(details?.end?._hex, 16);

//       let linkedTickets = [];
//       let linkedTicketCollectibles = [];
//       let linkedTicketFeaturedOffers = [];


//       for (const i of linkedCollections) {
//         const collectionType = await CollectionManager.getCollectionType(i);

//         if (collectionType == CollectionType.TICKET) {
//           linkedTickets.push(i);
//         }
//       }

//       if (linkedTickets?.length) {
//         for (const i of linkedTickets) {
//           const linkedCollectiblesForTickets = await collectionHelper.getLinkedCollections(i);
//           if (linkedCollectiblesForTickets?.length) {
//             for (const x of linkedCollectiblesForTickets) {
//               const collectionData = await CollectionManager.collectionData(x);
//               if (collectionData.offerType == OfferType.NOTANYOFFER) {
//                 linkedTicketCollectibles.push(x)
//               } else if (collectionData.offerType == OfferType.FEATURED) {
//                 linkedTicketFeaturedOffers.push(x)
//               }
//             }
//           }
//         }
//       }



//       let event = {
//         index: index,
//         owner: owner,
//         dataURI: "",
//         collaborators: allCollaborators,
//         inviteCollaborators: allCollaborators.length ? true : false,
//         eventTickets: linkedTickets,
//         linkedTicketCollectibles: linkedTicketCollectibles,
//         linkedTicketFeaturedOffers: linkedTicketFeaturedOffers,

//         getKeepers: allGatekeepers,
//         imageProps: {
//           image: "",
//           imageSize: 0,
//           thumbnailImage: "",
//           thumbnailImageSize: 0,
//           optimizedImage: "",
//           optimizedImageSize: 0,
//         },
//         details: "",
//         name: "",
//         address: address,
//         symbol: "",
//         isActive: true,
//         entityAddress: "",
//         price: '0.00',
//         priceRate: 0,
//         start: null,
//         end: null,
//         category: CATEGORIES.OTHER,
//         offerType: OfferType.NOTANYOFFER,
//         collectionType: CollectionType.ANY,
//         linkCollectible: linkedCollections,
//         subTitle: "",
//         whitelistedCollections: [],
//         totalSupply: 0,
//         tokensEarned: 0,
//         privateMessageCap: 0,
//         maxMint: 0,
//         maxPurchase: 0,
//         chain: getNetwork(),
//         htmlTemplate: null,
//         mintWithLinked: false,
//         isPremium: false,
//         isVideoCollectible: false,
//         video: "",
//         isCoupon: false,
//         mintWithLinkedOnly: false,
//         area: areaCoordinates,
//         maxBalance: 0,
//         socialMedia: true,
//         socialLinks: null,
//         passportType: PassportType.REGULAR
//       };
//       event.name = details?.name;
//       event.dataURI = details?.dataURI;
//       event.start = (new Date(Number(startDate ?? startDate) * 1000))
//       event.end = (new Date(Number(endDate ?? endDate) * 1000));
//       if (details?.dataURI) {
//         let parsedData;
//         if (details?.dataURI?.includes("http")) {
//           parsedData = await fetch(details?.dataURI);
//         } else {
//           const temp = getIPFSLink(details?.dataURI);
//           parsedData = await fetch(temp);
//         }
//         const ipfsData = await parsedData.json();

//         if (ipfsData) {
//           if (ipfsData?.description) {
//             event.details = ipfsData?.description;
//           }
//           if (ipfsData?.image) {
//             event.imageProps.image = getIPFSLink(ipfsData?.image);
//             event.imageProps.imageSize = ipfsData?.imageSize;
//             event.imageProps.optimizedImage = getIPFSLink(ipfsData?.optimizedImage);
//             event.imageProps.thumbnailImage = getIPFSLink(ipfsData?.thumbnailImage);
//             event.imageProps.thumbnailImageSize = ipfsData?.thumbnailImageSize;
//             event.imageProps.optimizedImageSize = ipfsData?.optimizedImageSize;

//           }
//         }
//       }

//       dispatch(updateEventsData(event));
//       allEventDetails.push(event);
//       await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(address), details);
//     })





//   }







//   // console.log({details});

//   // let allEventsDetails = [];
//   // let eventAddressList = (await getAllCollectibleList({ networkID, provider, collectibleType : CollectionType.TICKET, entity: entityAddress }));
//   // if( eventAddressList && eventAddressList.length > 0 ) {

//   //   await Promise.all( eventAddressList.filter(eventAddress => eventAddress?.source !== ZERO_ADDRESS ).map(async (eventAddress, _index) => {
//   //     let eventDetails : ICollectibleDetail = (await getCollectibleDetails(
//   //       { networkID, provider,userAddress:wallet?.address, collectibleData : eventAddress, index: _index, entityAddress}, 
//   //       {isCache,fetchLatest:true}
//   //     ));

//   //     if(eventDetails && eventDetails?.name !== "") {
//   //       dispatch(updateEventsData(eventDetails));
//   //       allEventsDetails.push(eventDetails);
//   //     }
//   //   }));
//   // }

//   // if(allEventsDetails && allEventsDetails.length === 0) {
//   //   dispatch(updateEventsData(null));
//   // }

//   // LogToConsole("all Events",allEventsDetails);



// });

// // export const getCollectibleDetails = async ({ networkID, provider, userAddress, collectibleData, index, entityAddress, isSuperAdmin }: ICollectibleAsyncThunk, { isCache = true, fetchLatest = false }): Promise<ICollectibleDetail> => {

// export const loadEventDetails = async ({ networkID, provider, eventAddress, index }: IEventAsyncThunk): Promise<any> => {

//   // if (eventAddressList?.length) {

//   const EventFactory = Event__factory.connect(eventAddress, provider);
//   const collectionHelper = CollectionHelper__factory.connect(addresses[networkID].CollectionHelper, provider);
//   const CollectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);

//   const linkedCollections = await collectionHelper.getLinkedCollections(eventAddress);

//   const details = await EventFactory.eventData();
//   const area = await EventFactory.getEventArea();
//   const radius = parseInt(area?.radius?._hex, 16);
//   let coordinates;
//   if (area?.points?.length == 2) {
//     coordinates = area?.points
//   } else {
//     coordinates = [area?.points[0]?.split(',')[0], area?.points[0]?.split(',')[1]]
//   }

//   const areaCoordinates = convertAreaData(coordinates, Number(radius));

//   const allCollaborators = await EventFactory.getAllCollaborators(true);
//   const allGatekeepers = await EventFactory.getAllGatekeepers(true);
//   const owner = await EventFactory.owner();
//   const startDate = parseInt(details?.start?._hex, 16);
//   const endDate = parseInt(details?.end?._hex, 16);
//   let linkedTickets = [];


//   for (const i of linkedCollections) {
//     const collectionType = await CollectionManager.getCollectionType(i);

//     if (collectionType == CollectionType.TICKET) {
//       linkedTickets.push(i);
//     }
//   }

//   let linkedTicketCollectibles = [];
//   let linkedTicketFeaturedOffers = [];

//   if (linkedTickets?.length) {
//     for (const i of linkedTickets) {
//       const linkedCollectiblesForTickets = await collectionHelper.getLinkedCollections(i);
//       if (linkedCollectiblesForTickets?.length) {
//         for (const x of linkedCollectiblesForTickets) {
//           const collectionData = await CollectionManager.collectionData(x);
//           if (collectionData.offerType == OfferType.NOTANYOFFER) {
//             linkedTicketCollectibles.push(x)
//           } else if (collectionData.offerType == OfferType.FEATURED) {
//             linkedTicketFeaturedOffers.push(x)
//           }
//         }
//       }
//     }
//   }


//   let event = {
//     index: index,
//     owner: owner,
//     dataURI: "",
//     inviteCollaborators: allCollaborators.length ? true : false,
//     collaborators: allCollaborators,
//     eventTickets: linkedTickets,
//     linkedTicketCollectibles: linkedTicketCollectibles,
//     linkedTicketFeaturedOffers: linkedTicketFeaturedOffers,
//     getKeepers: allGatekeepers,
//     imageProps: {
//       image: "",
//       imageSize: 0,
//       thumbnailImage: "",
//       thumbnailImageSize: 0,
//       optimizedImage: "",
//       optimizedImageSize: 0,
//     },
//     details: "",
//     name: "",
//     address: eventAddress,
//     symbol: "",
//     isActive: true,
//     entityAddress: "",
//     price: '0.00',
//     priceRate: 0,
//     start: null,
//     end: null,
//     category: CATEGORIES.OTHER,
//     offerType: OfferType.NOTANYOFFER,
//     collectionType: CollectionType.ANY,
//     linkCollectible: linkedCollections,
//     subTitle: "",
//     whitelistedCollections: [],
//     totalSupply: 0,
//     tokensEarned: 0,
//     privateMessageCap: 0,
//     maxMint: 0,
//     maxPurchase: 0,
//     chain: getNetwork(),
//     htmlTemplate: null,
//     mintWithLinked: false,
//     isPremium: false,
//     isVideoCollectible: false,
//     video: "",
//     isCoupon: false,
//     mintWithLinkedOnly: false,
//     area: areaCoordinates,
//     maxBalance: 0,
//     socialMedia: true,
//     socialLinks: null,
//     passportType: PassportType.REGULAR
//   };
//   event.name = details?.name;
//   event.dataURI = details?.dataURI;
//   event.start = (new Date(Number(startDate ?? startDate) * 1000))
//   event.end = (new Date(Number(endDate ?? endDate) * 1000));
//   if (details?.dataURI) {
//     let parsedData;
//     if (details?.dataURI?.includes("http")) {
//       parsedData = await fetch(details?.dataURI);
//     } else {
//       const temp = getIPFSLink(details?.dataURI);
//       parsedData = await fetch(temp);
//     }
//     const ipfsData = await parsedData.json();

//     if (ipfsData) {
//       if (ipfsData?.description) {
//         event.details = ipfsData?.description;
//       }
//       if (ipfsData?.image) {
//         event.imageProps.image = getIPFSLink(ipfsData?.image);
//         event.imageProps.imageSize = ipfsData?.imageSize;
//         event.imageProps.optimizedImage = getIPFSLink(ipfsData?.optimizedImage);
//         event.imageProps.thumbnailImage = getIPFSLink(ipfsData?.thumbnailImage);
//         event.imageProps.thumbnailImageSize = ipfsData?.thumbnailImageSize;
//         event.imageProps.optimizedImageSize = ipfsData?.optimizedImageSize;

//       }
//     }
//   }

//   // dispatch(updateEventsData(event));
//   // allEventDetails.push(event);
//   await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(eventAddress), details);
//   return event
// }


export const loadAllPremiumChats = createAsyncThunk("offers/loadAllChatRooms", async ({ networkID, provider, wallet, entityAddress, isCache = true, isSuperAdmin }: ICollectionBaseAsyncThunk, { dispatch }): Promise<any> => {

  let allPremiumDetails = [];
  let allPremiumList = (await getAllCollectibleList({ networkID, provider, collectibleType: CollectionType.PREMIUM_ACCESS, entity: entityAddress }));

  if (allPremiumList && allPremiumList.length > 0) {
    await Promise.all(allPremiumList.filter(collectibleAddress => collectibleAddress?.source !== ZERO_ADDRESS).map(async (collectibleAddress, _index) => {
      let premiumChatDetails: ICollectibleDetail = (await getCollectibleDetails(
        { networkID, provider, userAddress: wallet?.address, collectibleData: collectibleAddress, index: _index, entityAddress, isSuperAdmin },
        { isCache }
      ));

      if (premiumChatDetails && premiumChatDetails.name !== "") {
        dispatch(updatePremiumChatData(premiumChatDetails));
        allPremiumDetails.push(premiumChatDetails);
      }
    }));
  }
  if (allPremiumDetails && allPremiumDetails.length === 0) {
    dispatch(updatePremiumChatData(null));
  }

  LogToConsole("all premium", allPremiumDetails);

});

export const loadAllDigitalCollectibles = createAsyncThunk("offers/loaAllDGCollectibleList", async ({ networkID, provider, wallet, entityAddress, isCache = true, isSuperAdmin }: ICollectionBaseAsyncThunk, { dispatch }): Promise<any> => {

  let allDigitalCollectiblesDetails = [];
  let digitalCollectibleAddressList = (await getAllCollectibleList({ networkID, provider, collectibleType: CollectionType.COLLECTION, entity: entityAddress }));
  if (digitalCollectibleAddressList && digitalCollectibleAddressList.length > 0) {

    await Promise.all(digitalCollectibleAddressList.filter(digitalCollectibleAddress => digitalCollectibleAddress?.source !== ZERO_ADDRESS).map(async (digitalCollectibleAddress, _index) => {
      let digitalCollectibleDetails: ICollectibleDetail = (await getCollectibleDetails(
        {
          networkID, provider,
          isSuperAdmin,
          userAddress: wallet?.address,
          collectibleData: digitalCollectibleAddress, index: _index, entityAddress
        },
        { isCache, fetchLatest: true }
      ));

      if (digitalCollectibleDetails && digitalCollectibleDetails?.name !== "") {
        if (digitalCollectibleDetails.offerType === OfferType.FEATURED) {
          dispatch(updateFeaturedOfferData(digitalCollectibleDetails));
        }
        else {
          dispatch(updateDigitalCollectionData(digitalCollectibleDetails));
        }
        allDigitalCollectiblesDetails.push(digitalCollectibleDetails);
      }
    }));
  }
  if (allDigitalCollectiblesDetails && allDigitalCollectiblesDetails.length === 0) {
    dispatch(updateDigitalCollectionData(null));
    dispatch(updateFeaturedOfferData(null));
  }

  LogToConsole("all Digitals", allDigitalCollectiblesDetails);

});

const OfferSlice = createSlice({
  name: "OfferDetails",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },

    updateCollectibleSocialData(state, action) {
      let collectibleData = state.AllEntityDigitalCollectiblesDetails?.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
      collectibleData.socialMedia = action.payload.socialMedia;
    },
    updateOfferSocialData(state, action) {
      let collectibleData = state.AllEntityFeturedOfferDetails?.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
      collectibleData.socialMedia = action.payload.socialMedia;
    },
    removeTransferedBillboards(state, action) {
      let addressOfBillBoardToBeRemoved = action?.payload?.address;
      const allPassports = state?.AllEntityDigitalCollectiblesDetails ?? [];
      state.AllEntityDigitalCollectiblesDetails = allPassports?.filter(i => i?.address?.toLowerCase() != addressOfBillBoardToBeRemoved?.toLowerCase());
      //  = filteredPassports?.sort((a,b) => Number(b.index) - Number(a.index));
    },
    removeTransferedPremiumChats(state, action) {
      let addressOfChatToBeRemoved = action?.payload?.address;
      const allPassports = state?.AllEntityPremiumChatDetails ?? [];
      state.AllEntityPremiumChatDetails = allPassports?.filter(i => i?.address?.toLowerCase() != addressOfChatToBeRemoved?.toLowerCase());
      //  = filteredPassports?.sort((a,b) => Number(b.index) - Number(a.index));
    },
   
    updateFeaturedOfferData(state, action) {
      let allCollectibleData = state.AllEntityFeturedOfferDetails ?? [];
      if (action.payload) {
        let collectionData = allCollectibleData.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
        if (collectionData) {
          allCollectibleData = allCollectibleData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
          collectionData = { ...collectionData, ...action.payload };
        }
        else {
          collectionData = action.payload;
        }
        allCollectibleData.push(collectionData);
      }
      state.AllEntityFeturedOfferDetails = allCollectibleData.sort((a, b) => Number(b.index) - Number(a.index));
    },
    // updateRegularOfferData(state, action) {
    //   let allCollectibleData = state.AllEntityRegularOfferDetails ?? [];
    //   if(action.payload) {      
    //     let collectionData = allCollectibleData.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
    //     if(collectionData) {
    //       allCollectibleData = allCollectibleData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
    //       collectionData = { ...collectionData, ...action.payload };  
    //     }
    //     else {
    //       collectionData = action.payload;
    //     }
    //     allCollectibleData.push(collectionData);
    //   }
    //   state.AllEntityRegularOfferDetails = allCollectibleData.sort((a,b) => Number(b.index) - Number(a.index));
    // },
   
    updateDigitalCollectionData(state, action) {
      let allCollectibleData = state.AllEntityDigitalCollectiblesDetails ?? [];
      if (action.payload) {
        let collectionData = allCollectibleData.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
        if (collectionData) {
          allCollectibleData = allCollectibleData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
          collectionData = { ...collectionData, ...action.payload };
        }
        else {
          collectionData = action.payload;
        }

        allCollectibleData.push(collectionData);
      }
      state.AllEntityDigitalCollectiblesDetails = allCollectibleData.sort((a, b) => Number(b.index) - Number(a.index));
    },
    updatePremiumChatData(state, action) {
      let allCollectibleData = state.AllEntityPremiumChatDetails ?? [];
      if (action.payload) {
        let collectionData = allCollectibleData.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
        if (collectionData) {
          allCollectibleData = allCollectibleData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
          collectionData = { ...collectionData, ...action.payload };
        }
        else {
          collectionData = action.payload;
        }
        allCollectibleData.push(collectionData);
      }
      state.AllEntityPremiumChatDetails = allCollectibleData.sort((a, b) => Number(b.index) - Number(a.index));
    },
    setOffersLoading(state, action) {
      state.Offersloading = action.payload;
    },
   
    setCollectiblesLoading(state, action) {
      state.DigitalCollectibleloading = action.payload;
    },
    setPremiumChatsLoading(state, action) {
      state.PremiumChatsLoading = action.payload;
    },
    clearAllCollectionData(state) {
      state.AllEntityDigitalCollectiblesDetails = [];
      // state.AllEntityEventsDetails = [];
      state.AllEntityFeturedOfferDetails = [];
      // state.AllEntityRegularOfferDetails = [];
      state.AllEntityPremiumChatDetails = [];

    }
  },
  extraReducers: builder => {
    builder
      // .addCase(loadAllOffers.pending, (state, action) => {
      //   // state.Offersloading = true;
      // })
      // .addCase(loadAllOffers.fulfilled, (state, action) => {
      //   state.Offersloading = false;
      // })
      // .addCase(loadAllOffers.rejected, (state , error : any ) => {
      //   state.Offersloading = false;
      //   LogToConsoleError("loadAllOffers", error.name, error.message, error.stack);
      // })
    
      .addCase(loadAllDigitalCollectibles.pending, (state, action) => {
        // state.DigitalCollectibleloading = true;
      })
      .addCase(loadAllDigitalCollectibles.fulfilled, (state, action) => {
        state.DigitalCollectibleloading = false;
        state.Offersloading = false;
      })
      .addCase(loadAllDigitalCollectibles.rejected, (state, { error }: any) => {
        state.DigitalCollectibleloading = false;
        state.Offersloading = false;
        LogToConsoleError("loadAllDigitalCollectibles", error.name, error.message, error.stack);
      })
      .addCase(loadAllPremiumChats.pending, (state, action) => {
        // state.PremiumChatsLoading = true
      })
      .addCase(loadAllPremiumChats.fulfilled, (state, action) => {
        state.PremiumChatsLoading = false
      })
      .addCase(loadAllPremiumChats.rejected, (state: { PremiumChatsLoading: boolean }, { error }: any) => {
        state.PremiumChatsLoading = false
        LogToConsoleError("loadAllPremiumChats", error.name, error.message, error.stack);
      })
  }

});

export const OfferSliceReducer = OfferSlice.reducer;

const baseInfo = (state: RootState) => state.Offers;

export const { fetchAppSuccess, updateCollectibleSocialData, updateDigitalCollectionData, updateOfferSocialData,
  updateFeaturedOfferData, updatePremiumChatData, setOffersLoading, removeTransferedPremiumChats,
   setCollectiblesLoading, removeTransferedBillboards, setPremiumChatsLoading, clearAllCollectionData } = OfferSlice.actions;

export const getOfferState = createSelector(baseInfo, OfferSlice => OfferSlice);
