import { gql } from "urql";

export const collectionMetadataQuery = gql`
  query($address: String!) {
    collectionMetadata(address: $address) {
      collection
      name
      symbol
      dataURI
      isActive
      areaPoints
      areaRadius
      linkedCollections
      collectionType
    }
  }
`;

export const collectionDataQuery = gql`
  query($address: String!) {
    collectionData(address: $address) {
      collection
      entity
      mintWithLinked
      price
      maxPurchase
      start
      end
      checkInNeeded
      maxMint
      offerType
      passport
      minRewardBalance
      minVisits
      minFriendVisits
    }
  }
`;

export const collectionAdditionalDataQuery = gql`
  query($address: String!) {
    collectionDataAdditional(address: $address) {
      collection
      maxBalance
      isPremium
      mintWithLinkedOnly
      isCoupon
      mintModel
    }
  }
`;

export const collectionDetailsCombinedQuery = gql`
  query($address: String!) {
    collectionData(address: $address) {
      collection
      entity
      mintWithLinked
      price
      maxPurchase
      start
      end
      checkInNeeded
      maxMint
      offerType
      passport
      minRewardBalance
      minVisits
      minFriendVisits
    }
    collectionMetadata(address: $address) {
      collection
      name
      symbol
      dataURI
      isActive
      areaPoints
      areaRadius
      linkedCollections
      collectionType
    }
    collectionDataAdditional(address: $address) {
      collection
      maxBalance
      mintWithLinkedOnly
      isCoupon
      mintModel
    }
  }
`;

export const collectionAddedEventQuery = gql`
  query($address: String!) {
    collectionAddedEvent(address: $address) {
      address
      chainId
      collectionType
      blockNumber
      transactionHash
      transactionIndex
    }
  }
`;

export const whitelistedCollectionsQuery = gql`
  query($address: String!) {
    whitelistedCollectionsForPassport(passport: $address) {
      whitelistedCollections {
        source, 
        chainId
      }
    }
  }
`;

export const collectibleTransfersQuery = gql`
  query ($address: String!, $from: String!, $page: Int!, $pageSize: Int!){
    collectibleTransfers(collectible: $address, from: $from, page: $page, pageSize: $pageSize) {
      count
      records {
        collection
        blockNumber
        from
        to
        tokenId
        transactionHash
        chainId
      }
    }
  }
`;

export const fetchPOAPCollectionsQuery = gql `
  query($entity:String!){
   poapCreatedCollections(entity:$entity, collection:null) {
    count,
    records{
      blockNumber
      entity
      collection
      transactionHash
    }
   }
  }
`