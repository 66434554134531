import { PrivateForwarder } from "../typechain/PrivateForwarder";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { addresses, MASTER_KEY, NetworkId, NETWORKS, WEBHOOK_URL } from "../constants";
import { RootState } from "../store";
import { setAll } from "./helpers";
import { IMessageMetaData } from "./interfaces";
import {  PrivateForwarder__factory, PrivateOwnableForwarder__factory } from "../typechain/factories";
import { ethers, Wallet } from "ethers";
import { wait } from "../helpers/ipfs";
import AwaitLock from "../helpers/AwaitLock";
import { PrivateOwnableForwarder } from "../typechain";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";

let lock = new AwaitLock();

const buildRequest = async (forwarder: PrivateForwarder | PrivateOwnableForwarder, input: any, networkID, wallet: Wallet, prevNonce: string): Promise<any> => {
  let nonce = (await forwarder.getNonce(input.from)).toString();
  try {
    while(prevNonce && prevNonce === nonce){
      await wait(500);
      nonce = (await forwarder.getNonce(input.from)).toString();
    }
  }
  catch(e) {
    LogToConsoleError('Failed to fetch nonce', e);
   }
  let gas = 2e6;
  try {
    //const gasPrice = await forwarder.provider.getGasPrice();
    const request = { value: 0, gas: 15e6, nonce, ...input };

    let toSign = await buildTypedData(forwarder, request);
    let signature = await wallet._signTypedData(toSign.domain, toSign.types, toSign.message);
    const data = forwarder.interface.encodeFunctionData("execute", [request, signature]);

    const gasEstimated: number = (await forwarder.provider.estimateGas({
       from: addresses[networkID].RelayerAddress,
       to: addresses[networkID].PrivateForwarder,
       data: data })).toNumber();

    const stackReserve = Math.round(gasEstimated / 64);
    // real usage by internal call
    const intUsage = gasEstimated - stackReserve;
    // add markup to cover gas fluctuations
    gas = Math.round(intUsage * (1.10));
    LogToConsole(`new gasEstimation::gasEstimated: ${gasEstimated} stackReserve: ${stackReserve} intUsage: ${intUsage}; with markup: ${gas}; with nonce ${nonce}`);        //new calculation-end

  }
  catch (e) {
    LogToConsoleError("timeend-error:", new Date().getTime(), nonce, e);
  }
  return { value: 0, gas: gas, nonce, ...input };
}

const buildTypedData = async (forwarder: PrivateForwarder | PrivateOwnableForwarder, request: any) => {
  const chainId = (await forwarder.provider.getNetwork()).chainId;
  const typeData = getMetaTxTypeData(chainId, forwarder.address);
  return { ...typeData, message: request };
}

export interface IAppData {
  readonly msgLoading: boolean;
  readonly nonce: string;
}

const initialState: IAppData = {
  msgLoading: false,
  nonce: ""
};

const getMetaTxTypeData = (chainId: number, verifyingContract: string): any => {
  return {
    types: {
      ForwardRequest: [
        { name: "from", type: "address" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "gas", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "data", type: "bytes" },
      ],
    },
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest'
  }
};

export const SendMetaTX = createAsyncThunk("app/SignMetaTX", async ({ networkID, provider, to, wallet, data }: IMessageMetaData, { dispatch, getState }): Promise<any> => {
  await lock.acquireAsync();
  const startTime = new Date().getTime();
  try {
    LogToConsole("transaction start", startTime)
    let res = await dispatch(SendMetaTXCall({ networkID, provider, to, wallet, data }));
    return res.payload;
  } finally {
    const endTime = new Date().getTime();
    LogToConsole("transaction end", endTime, "Total time took:", (endTime-startTime)/1000, 'seconds');
    lock.release();
  }
});

export const SendMetaTXCall = createAsyncThunk("app/SendMetaTx", async ({ networkID, provider, to, wallet, data }: IMessageMetaData, { dispatch, getState} ) : Promise<any> => {
  try { 
    const state = getState() as RootState;
    const from = await wallet.getAddress();
    const ADDRESS_FORWADER = addresses[networkID].PrivateForwarder;
    let private_forwarder: any;

    if( networkID === NetworkId.POLYGON_MAINNET || networkID === NetworkId.POLYGON_MUMBAI_TESTNET ) {
      private_forwarder = PrivateOwnableForwarder__factory.connect(ADDRESS_FORWADER, provider)
    }
    else {
      private_forwarder = PrivateForwarder__factory.connect(ADDRESS_FORWADER, provider);
    }
    const request = await buildRequest(private_forwarder, { from, to, data }, networkID, wallet, state.App.nonce);
    await dispatch(updateNonce(request.nonce));
    const toSign = await buildTypedData(private_forwarder, request);
    const signature = await wallet._signTypedData(toSign.domain, toSign.types, toSign.message);

    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const masterSignature = await masterWallet.signMessage(signature);
    const body = JSON.stringify({ signature, request, masterSignature });
    const submissionTime = new Date().getTime();
    
    let response = null;
    try {
    let responseData = await fetch(WEBHOOK_URL(networkID), {
      method: "POST",
      body: body,
      headers: { "Content-Type": "application/json" },
    });
    response = await responseData.json();
    LogToConsole("relay-response", response);
    }
    catch(e) {
      LogToConsoleError("WEBHOOK ERROR", e);
      await dispatch(updateNonce(""));
    }

    let waitResponseLogs;
    if (response && response?.status === 'success') {
      let tx = (JSON.parse(response?.result)).txHash;
      const transactionStart = new Date().getTime();
      const txReceipt = provider.waitForTransaction(tx);
      LogToConsole("txReceipt", txReceipt);
      let waitResponse : any = await Promise.race([txReceipt, wait(20000)]);//timeout in millisecond
      LogToConsole("waitResponse", waitResponse);
      waitResponseLogs = waitResponse?.logs;
      if (!waitResponse) {
        LogToConsole("calling waitrepsonse again");
        waitResponse = await Promise.race([txReceipt, wait(60000)]);//timeout in millisecond    
        LogToConsole("waitResponse", waitResponse);
        if (waitResponse) {
          const transactionEnd = new Date().getTime();
          const timeDiffernt = transactionEnd - transactionStart;
          waitResponseLogs = waitResponse?.logs;
          //transactionHash,autoTaskRunId,blockNumber,networkID,submissionTime
          LogToConsoleError("META-TX-TIME-WARNING", to, networkID, [
            { tag: "autotaskId", value: response.autotaskId },
            { tag: "autotaskRunId", value: response.autotaskRunId },
            { tag: "blockNumber", value: waitResponse?.blockNumber },
            { tag: "processingTime", value: timeDiffernt },
            { tag: "processStartTime", value: transactionStart },
            { tag: "submissionTime", value: submissionTime },
            { tag: "txReceipt", value: tx }]);
        }
        else {
          LogToConsoleError("META-TX-TIMEOUT", to, networkID, [
            { tag: "autotaskId", value: response.autotaskId },
            { tag: "autotaskRunId", value: response.autotaskRunId },
            { tag: "atLog", value: response.autotaskRunId },
            { tag: "submissionTime", value: submissionTime },
            { tag: "processStartTime", value: transactionStart },
            { tag: "txReceipt", value: tx }]);
          return { status: "Error", errMsg: "Something went wrong. Please try again later." }
        }
      }
    }
    else {
      LogToConsoleError("META-TX-ERROR", to, networkID,
        [{ tag: "autotaskId", value: response.autotaskId },
        { tag: "autotaskRunId", value: response.autotaskRunId },
        { tag: "status", value: response.status },
        { tag: "message", value: response.message }]);
    }
    return { status: "Success", response: response, eventLogs: waitResponseLogs };

  }
  catch (e) {
    return { status: "Error", errMsg: "Something went wrong. Please try again later." }
  }
});

const AppSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updateNonce(state, action) {
      state.nonce = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SendMetaTX.pending, (state: { msgLoading: boolean; }) => {
        state.msgLoading = true;
      })
      .addCase(SendMetaTX.fulfilled, (state: { msgLoading: boolean; }, action: { payload: any; }) => {
        state.msgLoading = false;
      })
      .addCase(SendMetaTX.rejected, (state: { msgLoading: boolean; }, { error }: any) => {
        state.msgLoading = false;
        LogToConsoleError("SendMetaTX", error.name, error.message, error.stack);
      })
  },
});

export const AppSliceReducer = AppSlice.reducer;

const baseInfo = (state: RootState) => state.App;

export const { fetchAppSuccess, updateNonce } = AppSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
