import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormGroup, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { NameTextInput } from '../../../../components/TextInput/NameTextInput/NameTextInput';
import { DescriptionTextInput } from '../../../../components/TextInput/DescriptionTextInput/DescriptionTextInput';
import NetworkSelect from '../../../../components/Dropdown/NetworkSelect/NetworkSelect';
import { CountInput } from '../../../../components/TextInput/NumberInput/CountInput';
import { SelectInput } from '../../../../components/Dropdown/SelectDropdown/SelectInputDropdown';
import { ImageUploadCard } from '../../components/ImageUploadCard/ImageUploadCard';
import { useAppSelector, useWeb3Context } from '../../../../hooks';
import { CatalogTypes } from '../../../../enums/catalog.enum';
import { CustomButton } from '../../../../components/Button/CustomButton';
import { defaultTheme } from '../../../../styles';
import { blobIpfsUpload, fileIpfsUpload, jsonIpfsUpload } from '../../../../services/ipfsUpload.service';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { AddCollection, refreshPassportWhitelistedCollections, toggleCollectionSociaMedialAccess } from '../../../../slices/PassportSlice';
import { IMAGE_REGEX, NetworkId, VIDEO_REGEX, ZERO_ADDRESS, getAnynetStaticProvider, isProduction, isStaging } from '../../../../constants';
import { IPOAPCollectibleDetail } from '../../../../interfaces/IPOAPCollection.interface';
import { CreatePOAPCollection, delistCollection, getPOAPCollectibleDetails, loadAllPOAPCollectibleDetails, pushPhygitalData, refreshPOAPCollectibleDetails, updatePoapSocialData, whitelistCollection } from '../../../../slices/collectibleSlice';
import { PDFGenerator } from '../../../../components/PDFGenerator/PDFGenerator';
import { getIpfsCID, wait } from '../../../../helpers/ipfs';
import { ConfirmModal } from '../../../../components/ModalComponents/ConfirmModal';
import { CsvUploadComponent } from '../../../../components/CSVtoQR/CSVtoQR';
import { CustomCheckbox } from '../../../../components/CheckBox/CheckBox';
import { CustomSwitch } from '../../../../components/CheckBox/CustomSwitch';
import { LogToConsoleError } from '../../../../helpers/Logger';
import { useAlert } from '../../../../hooks/Alert';
import EditPhygitalModal from '../EditPhygitalModal/EditPhygitalModal';
import { ExpandMore } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LightTooltip, socialMediaText } from './CollectionFormContainer';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { getThumbnailFromVideo } from '../../../../slices/helpers';
import { compressImage } from '../../../../services/imageHelper.service';


const GridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  background: '#FFFFFF14 0% 0% no-repeat padding-box',
  borderRadius: 6
  
}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

interface Props {
  collectionData: IPOAPCollectibleDetail;
  type: CatalogTypes.EXTERNAL,
  firstCard?:any;
  isFormForNewPassport?:boolean;
  setIsFormForNewPassport:(item:boolean)=>void
  setLoading: ({ open, message } : { open: boolean, message: string }) => void;
  setSelectedCard: (collection : IPOAPCollectibleDetail) => void;
  setIsCollectibleModalOpen: (value: boolean) => void;
  isCollectibleModalOpen: boolean;

}

export const ExternalCollectionFormContainer = ({firstCard,setIsFormForNewPassport,isFormForNewPassport,collectionData, type, setLoading, setSelectedCard, isCollectibleModalOpen, setIsCollectibleModalOpen } : Props) => {

  const { address, wallet, networkId, staticProvider } = useWeb3Context();
  const dispatch = useDispatch<AppDispatch>();

  
  const [formInput, setFormInput] = useState(collectionData);
  const [backgroundImage, setBackgroundImage] = useState(collectionData.imageProps?.image);
  const [backgroundVideo, setBackgroundVideo] = useState(collectionData?.video);

  const [countChecked, setCountChecked] = useState(false);
  const [fromTokenCount, setFromTokenCount] = useState(1);
  const [toTokenCount, setToTokenCount] = useState(2);
  const [error,setError] = useState<string>("");

  const [traits, setTraits] = useState({file: "", data: null });

  const availablePassportDetails = useAppSelector(state => state.Passports.AllEntityPassportDetails ) ?? [];
  const availblePoapDetails = useAppSelector(state=>state.Collectibles.AllPOAPCollectibleDetails) ?? [];
  const EntityAddress = useAppSelector(state => state.Entity.EntityAddress);

 const { setShowToastError } = useAlert();

  // confirm pop
  const [showConfirmPopup, setShowConfirmPopup] = useState({ confirm : () => {}, state: false, message: ""});

  const [imageLoading, setImageLoading] = useState(false);

  const [showPdfModal, setShowPdfModal] = useState(false);

  const [expanded, setExpanded] = React.useState<boolean>(true);

  const handleChange = () => {
      setExpanded(!expanded);
  };

  const onModalClose = (isSubmit) => {
    if(!isSubmit) onCancel();
    setIsCollectibleModalOpen(false);
  }

  const onModalCancel = () => {
    setShowConfirmPopup({ confirm: ()=> {}, state: false, message: ""});
  }

  useEffect(() => {
      setFormInput(collectionData);
      setBackgroundImage(collectionData.imageProps?.image);
      setBackgroundVideo(collectionData?.video ?? null);
      setTraits({file: "", data: null });
      setCountChecked(false);
      setFromTokenCount(1);
      setToTokenCount(2);

  }, [collectionData])


  const imageChangeHandler = async (event: any) => {
    const { files } = event.target;
    if(files && files[0]) {

      if(IMAGE_REGEX.test(files[0].type.toString())){
        setBackgroundImage(URL.createObjectURL(files[0]));
        setFormInput({ ...formInput, isVideoCollectible: false, video: "",
          imageProps: { ...formInput?.imageProps,
            image: files[0],  imageSize: files[0].size 
          } 
        });
      }
      else if(VIDEO_REGEX.test(files[0].type.toString())) {
        let thumbnail = await getThumbnailFromVideo(files[0]);

        setFormInput({ ...formInput, video: files[0], isVideoCollectible: true,  
          imageProps: { ...formInput?.imageProps,
            image: thumbnail,  imageSize: 0
          }
        });
        
        setBackgroundVideo(URL.createObjectURL(files[0]));
      }

    }
  }


  // const thumbnailChangeHandler = async (event: any) => {
  //   const { files } = event.target;
  //   if(files && files[0]) {

  //     if(IMAGE_REGEX.test(files[0].type.toString())){
  //       setBackgroundImage(URL.createObjectURL(files[0]));
  //       setFormInput({ ...formInput, image: files[0], imageSize: files[0].size});
  //     }
  //   }
  // }

  const nameChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, name: text });
  }

  const descriptionChangeHandler = (event) => {
    let text = event.target.value;
    setFormInput({ ...formInput, details: text });
  }

  const linkcollectionChangedHandler = (events: any) => {
    let passports = events.target.value
    setFormInput({ ...formInput, linkCollectible: passports });
  }

  const networkChangeHandler = (event) => {
    setFormInput({ ...formInput, chain: event.target.value });
  }

  const maxTokenChangeHandler = (value) => {
    setFormInput({ ...formInput, autoStartMint: Number(value) });
  }

  const countCheckChangeHandler = (events: any) => {
    let checked = events.target.checked
    setCountChecked(checked);
  }

  const fromTokenCountChangeHandler = (value) => {
    setFromTokenCount(Number(value));  
  }
  const toTokenCountChangeHandler = (value) => {
    setToTokenCount(Number(value));
  }

  const traitsUploadChangeHandler = (event: any) => {
    const { files } = event.target;
    if(files && files[0]) {
        new Response(files[0]).json().then(json => {
            setTraits({ file: files[0].name, data: json })
          }, err => {
            setShowToastError({open: true, message: "Invalid json file"});
            LogToConsoleError("Not a valid json",err);
          })
    }
}

  const onCancel = () => {
    if(isFormForNewPassport){
      setSelectedCard(firstCard)
      setIsFormForNewPassport(false)
      }
    setFormInput(collectionData);
    setBackgroundImage(collectionData.imageProps?.image);
    setBackgroundVideo(collectionData?.video ?? null);
    setTraits({file: "", data: null });
  }

  useEffect(()=>{
    setSelectedCard(firstCard)
  },[firstCard])

  const onFormCreation = async (ipfsDataURI) => {

    setLoading({ open: true, message: "Creating POAP colllection" });

    //  creating a new poap
    const data =  await dispatch(CreatePOAPCollection({
        networkID: networkId, provider: staticProvider, address: address, EntityAddress: EntityAddress, collectibleData: formInput, dataURI : ipfsDataURI , wallet, chainID: formInput?.chain
    }));
    if( data.payload && data.payload !== ZERO_ADDRESS) {
      let collectionAddress = data.payload;
      // setLoading({ open: true, message: `Adding ${type} to manager` });

      await dispatch(AddCollection({ networkID: networkId, provider: staticProvider, address: address, EntityAddress: EntityAddress, category: 0, isPremium: false, collectibleAddress: collectionAddress, collectibleData: formInput, wallet, chainID: formInput?.chain }));


      return collectionAddress;
    }
    else {
      setLoading({ open: false, message: "" });
      LogToConsoleError("creation Error");
      setShowToastError({ open:true, message: "Collection creation error"});
    }
    return "";
  }

  const ipfsDataGeneration =async () => {

    let collectionImageIpfsUri = "" , collectionVideoIpfsUri = "";

    let collectionImageThumbnail = { uri:  getIpfsCID(formInput.imageProps?.thumbnailImage) , imageSize : formInput.imageProps?.thumbnailImageSize ?? 0 }, 
    collectionImageOptimized = { uri: getIpfsCID(formInput.imageProps?.optimizedImage), imageSize : formInput.imageProps?.optimizedImageSize ?? 0 };

    if(formInput?.isVideoCollectible) {
      collectionVideoIpfsUri  =  await fileIpfsUpload(formInput.video, collectionData.video, wallet);
      const optimizedImage = await compressImage(formInput.imageProps?.image, 1024, 2);
      collectionImageIpfsUri = await blobIpfsUpload(optimizedImage, collectionData.imageProps?.image, wallet);
     
    }
    else {
      collectionImageIpfsUri = await fileIpfsUpload(formInput.imageProps?.image, collectionData.imageProps?.image, wallet);

      if(formInput.imageProps?.image !== collectionData.imageProps?.image) {

        const thumbnailImage = await compressImage(formInput.imageProps?.image, 300, 5);

        collectionImageThumbnail.uri = await fileIpfsUpload(thumbnailImage, collectionData.imageProps?.thumbnailImage, wallet);
        collectionImageThumbnail.imageSize = thumbnailImage.size;

        const optimizedImage = await compressImage(formInput.imageProps?.image, 1024, 2);

        collectionImageOptimized.uri = await fileIpfsUpload(optimizedImage, collectionData.imageProps?.optimizedImage, wallet);
        collectionImageOptimized.imageSize = optimizedImage.size;
      }
    }

    const collectionObj = formInput?.isVideoCollectible ? 
      { 
        "name": formInput.name.trim(),
        "description": formInput.details.trim(),
        "image": formInput?.isVideoCollectible ?  "" : collectionImageIpfsUri,
        "video": collectionVideoIpfsUri,
        "subtitle" : type,
        "thumbnail" : formInput?.isVideoCollectible ? collectionImageIpfsUri : "",
        "imageSize": formInput?.imageProps?.imageSize,
        ...traits.data

      } :
      {
        "name": formInput.name.trim(),
        "description": formInput.details.trim(),
        "image": collectionImageIpfsUri,
        "subtitle" : type,
        "imageSize": formInput?.imageProps?.imageSize,
        "thumbnailImage": collectionImageThumbnail.uri,
        "thumbnailImageSize": collectionImageThumbnail.imageSize,
        "optimizedImage": collectionImageOptimized.uri,
        "optimizedImageSize": collectionImageOptimized.imageSize,
        ...traits.data
      }
    const ipfsDataURI = await jsonIpfsUpload(collectionObj, wallet);
    return ipfsDataURI;
    
  }

  const onSubmitHandler = async (event) => {
    event?.preventDefault();
    if(formInput?.address!="" && formInput?.linkCollectible?.length==0){
      setError("Please associate a passport to continue");
      return 
    }
    if(JSON.stringify(collectionData) === JSON.stringify(formInput)) return;
    onModalClose(true);
    onModalCancel();
    try {
      let collectionAddress = formInput.address;

      if(collectionAddress === "" ) {

        setLoading({ open: true, message: "Uploading Image" });
        let ipfsDataURI = await ipfsDataGeneration();
        
        if( ipfsDataURI && ipfsDataURI !== "") {
          collectionAddress = await onFormCreation(ipfsDataURI);
        }
        else {
          setLoading({ open: false, message: "" });
          setShowToastError({ open:true, message: "Image upload error"});
          LogToConsoleError("IPFS Upload error");
          return;
        }
      }
      if(formInput?.linkCollectible !== collectionData?.linkCollectible) {

        let oldLinks = collectionData?.linkCollectible;
        let newLinks = formInput?.linkCollectible;

        let delinks = oldLinks?.filter(a => newLinks.findIndex(b => b === a ) === -1);

        setLoading({ open: true, message: "Linking Collections" });

        if( delinks && delinks.length > 0) {
            await Promise.all(delinks.map( async (collectible) => {
                await dispatch(delistCollection({
                  networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, collectibleAddressB: collectible, wallet,
                  chainID: formInput.chain 
                }));
            }));
        }

        let addlinks = newLinks?.filter(a => oldLinks.findIndex(b => b === a ) === -1);

        if( addlinks && addlinks.length > 0) {
            await Promise.all(addlinks.map( async (collectible) => {
                await dispatch(whitelistCollection({
                  networkID: networkId, provider: staticProvider, address, collectibleAddressA: collectionAddress, collectibleAddressB: collectible, wallet,
                  chainID: formInput.chain 
                }));
            }));
        }
      }



      if(formInput?.address==""){
      let newIndex=0;
      if(availblePoapDetails && availblePoapDetails?.length>0 && formInput?.address==""){
        newIndex = Number(availblePoapDetails.reduce((max, obj) => 
          obj.index > max.index ? obj : max, availblePoapDetails[0]).index) + 1
      }

      let temp = {...formInput};
      temp.address = collectionAddress;
      temp.index = newIndex;

      await loadDetails(temp);
      }

      if(formInput.address === "") {setSelectedCard({ ...collectionData, address: collectionAddress });}
    }
    catch (e) {
      LogToConsoleError(e);
    }
    await wait(1000);
    setLoading({ open: true, message: "Fetching updated details" });
    await dispatch(refreshPassportWhitelistedCollections({  networkID: networkId, provider: staticProvider }));
    if(formInput.address === "") {
    
      // await dispatch(loadAllPOAPCollectibleDetails({ networkID: networkId, provider: staticProvider, entityAddress: EntityAddress, isCache : true }));
    }
    else {
      await dispatch(refreshPOAPCollectibleDetails({ collecionData: formInput, isCache: true  }));
    }
    setLoading({ open: false, message: "" });
  }
  
  const loadDetails = async (collectibleData) => {

    const temp = await getPOAPCollectibleDetails({ networkID : isProduction ? NetworkId.ARBITRUM_NOVA : isStaging ? NetworkId.ARBITRUM_NOVA_FORK : NetworkId.ARBITRUM_SEPOLIA, provider: getAnynetStaticProvider(isProduction ? NetworkId.ARBITRUM_NOVA : isStaging ? NetworkId.ARBITRUM_NOVA_FORK : NetworkId.ARBITRUM_SEPOLIA), collectibleData: collectibleData  });
    dispatch(pushPhygitalData(temp))
  }


  const onPDFGenerate = async () => {

    if(countChecked) {
      if(Number(fromTokenCount) === 0 || Number(toTokenCount) === 0){
          setShowToastError({ open:true, message: "Token count should not be Zero"});
          return;
      }
      else if(Number(toTokenCount) >= Number(collectionData.autoStartMint)) {
          setShowToastError({ open:true, message: "To Token count should be less than "+ collectionData.autoStartMint});
          return;
      }
      else if(Number(toTokenCount) < Number(fromTokenCount)) {
          setShowToastError({ open:true, message: "To Token count should be greater than From Token count"});
          return;
      }
      else if(Number(toTokenCount) - Number(fromTokenCount)+1 > 100) {
          setShowToastError({ open:true, message: "PDF can be generated only for 100 tokens at a time"});
          return;
      }
    }

    setImageLoading(true);
    setShowPdfModal(true);
    try {


      if(formInput?.name.trim() !== collectionData?.name.trim() || formInput?.details.trim() !== collectionData?.details.trim() || formInput?.imageProps?.image !== backgroundImage || traits.file !== ""|| formInput?.video !== backgroundVideo) {
        let ipfsDataURI = await ipfsDataGeneration();    
          setFormInput({ ...formInput, dataURI: ipfsDataURI, video: backgroundVideo, 
            imageProps: { ...formInput.imageProps,  image: backgroundImage }
           });
        }
    }
    catch(e) {
        LogToConsoleError("IPFS upload error", e);
        setShowToastError({ open:true, message: "Unable to upload Image"});
        setShowPdfModal(false);
    }
    setImageLoading(false);

  }

  
  const onToggleSocialMediaSwitch = (checked) => {

    setShowConfirmPopup({ message: socialMediaText, confirm: () => {onSocialMediaToggle(checked)}, state: true, });
  }

  const onSocialMediaToggle = async (checked : any) => {
    setShowConfirmPopup({ state: false, message: "", confirm: () => { } });

    setFormInput({ ...formInput, socialMedia: checked });
    const responseData = await dispatch(toggleCollectionSociaMedialAccess({ wallet, collectionAddress: formInput.address, chainId: formInput.chain, status: !checked, passport: formInput?.linkCollectible?.at(0) }))
    
    dispatch(updatePoapSocialData({ ...collectionData, socialMedia: responseData.payload?.socialMedia }));

  }

  useEffect(()=>{
        if(error!=""){
          setTimeout(()=>{
            setError("")
          },5000)
        }
  },[error])


  return (
    <> 
    {
      isCollectibleModalOpen && 
      <EditPhygitalModal 
        modalOpen={isCollectibleModalOpen} 
        onModalClose={() => onModalClose(false)} 
        collectionData={collectionData} 
        formInput={formInput} 
        setFormInput={setFormInput} 
        type={type} 
        onSubmitHandler={onSubmitHandler}
      />
    }
    {!isCollectibleModalOpen && 
      <FormGroup className='h-100'>
        <GridContainer container paddingBottom={'1rem'}>
          <GridItem item xs={12} sm={12} md={4}>
            <ImageUploadCard 
              background={formInput.isVideoCollectible ? backgroundVideo : backgroundImage} 
              backgroundImageChangeHandler={imageChangeHandler} 
              fileTypes={"image/jpg, image/jpeg, image/png, video/*"} 
              isVideo={formInput.isVideoCollectible} 
              isDisabled={!formInput?.isActive || collectionData.address === ''}
            />
          </GridItem>
          <GridItem item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NameTextInput label={"Name"} name={formInput.name} nameChangeHandler={nameChangeHandler} required={true} disabled={!formInput?.isActive || collectionData.address === ''}/>

            <DescriptionTextInput description={formInput.details} descriptionChangeHandler={descriptionChangeHandler} required={false} disabled={!formInput?.isActive || collectionData.address === ''}/>
            { formInput.address !== "" && formInput.linkCollectible.length > 0 &&
              <>
                <div className='mt-2 d-flex align-items-center flex-nowrap'>
                  <CustomSwitch disabled={false} checked={formInput?.socialMedia} handleToggle={!formInput?.socialMedia ? onSocialMediaToggle : onToggleSocialMediaSwitch} label={(formInput?.socialMedia ? "Disable" : "Enable") +" Social Media"} />
                  <LightTooltip title={socialMediaText} placement="bottom" arrow>
                    <InfoRoundedIcon className='mt-2' fontSize='small' color='disabled' />
                  </LightTooltip>
                </div>
              </>
            }
            <SelectInput 
              label={ "Associated Passports" } 
              menuData={ availablePassportDetails.filter(obj => obj.isActive) } 
              handleOptionChange={linkcollectionChangedHandler} 
              selectedOptions={formInput.linkCollectible} 
              disabled={!formInput?.isActive || collectionData.address === ''}
            />
            {error!="" && 
            <Typography color={"red"} fontSize={"12px"} fontFamily={"Poppins-Medium"}>{error}</Typography>}
            {collectionData.address !== "" &&
              <CustomButton
                className={"col-5 col-sm-5 col-lg-5 mt-2"}
                onClick={onSubmitHandler}
                color={defaultTheme.buttonDarkTextColor}
                backgroundColor={defaultTheme.yellowColor}
                type={"button"}
                disabled={ 
                  formInput.address === "" ? 
                  collectionData.address === ''
                  : 
                  formInput.linkCollectible === collectionData.linkCollectible 
                }
              >
                SAVE
              </CustomButton>
            }
          </GridItem>
          <GridItem item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className='detail-container'>
              <Accordion
                sx={{ backgroundColor : 'transparent', margin: 0 }} 
                elevation={0}
                disableGutters
                expanded={expanded}
                onChange={handleChange}
              >
                <AccordionSummary
                  sx={{ margin: 0, borderBottom: '1px solid #70707070', }}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography variant="body2" 
                    color={defaultTheme.buttonDarkTextColor} 
                    fontFamily={'Poppins-Medium'}>
                    Advanced Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <NetworkSelect handleChange={networkChangeHandler} selectedChain={collectionData.chain} disabled={true} required={true}/>
                  { formInput?.address !== "" && 
                  <Box display={'flex'} flexDirection={{lg:'row',xl:'row',md:'column',sm:'column',xs:'column'}} gap={4} alignItems={'flex-end'}>
                    <Box sx={{ width: {lg:'48%',xl:'48%',xs:'100%',md:'100%',sm:'100%'} }}>
                      <CountInput  label={"Auto Token Mint Start"} count={collectionData.autoStartMint} countChangeHandler={maxTokenChangeHandler} required={true} disabled={true}/>
                    </Box>
                    <Box sx={{ width: {lg:'48%',xl:'48%',xs:'100%',md:'100%',sm:'100%'} }}>
                    <Button disabled={collectionData.address === ''} startIcon={<CloudUploadIcon color='disabled' />}  disableElevation component='label' className='h-100 w-100' sx={{ background: defaultTheme.newFieldColor,  fontFamily: "Poppins-Medium", ['&:hover']: { backgroundColor: defaultTheme.newFieldColor } }}  >
                    <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical',}} paddingY={'6px'} color={defaultTheme.buttonDarkTextColor} fontSize={'inherit'} >
                      {traits.file ? traits?.file : "Upload Traits"}
                    </Typography>
                      <input
                          accept="application/json"
                          type="file"
                          onChange={traitsUploadChangeHandler}
                          style={{ display: 'none' }}
                      />
                    </Button>
                    </Box>

                  </Box>
                  }
                
              { formInput?.address !== "" && <>
                { Number(formInput.autoStartMint) > 1 && <>
                    <CustomCheckbox label={"Generate with TokenID"} checked={countChecked} handleCheckChange={countCheckChangeHandler} disabled={false}/>
                    { countChecked && 
                    <div className='d-flex gap-4'>
                      <CountInput label={"From token"} count={fromTokenCount} countChangeHandler={fromTokenCountChangeHandler} required={true} disabled={false}/> 
                      <CountInput label={"To Token"} count={toTokenCount} countChangeHandler={toTokenCountChangeHandler} required={true} disabled={false}/> 
                    </div>
                    }
                    </>
                }

                { formInput?.isActive &&
                <Box 
                sx={{
                  display:'flex',
                  rowGap:{md:1,sm:1,xs:1},
                  columnGap:{xl:2,lg:2},
                  flexDirection:{lg:'row',xl:'row',sm:'column',xs:'column',md:'column'}
                }}
                className="d-flex justify-content-around mt-3 ">

                  <CustomButton
                    className={"col-sm-12 col-lg-5 col-xl-5 col-xs-12"} 
                    onClick={onCancel} 
                    color={defaultTheme.buttonDarkTextColor} 
                    backgroundColor={defaultTheme.buttonGreyColor} 
                    disabled={false} 
                    type={"button"} 
                  >
                    Cancel
                  </CustomButton>

                  <CustomButton 
                    className={"col-sm-12 col-lg-5 col-xl-5 col-xs-12"} 
                    onClick={onPDFGenerate}
                    color={defaultTheme.buttonLightTextColor} 
                    backgroundColor={'#ba3487'} 
                    disabled={false} 
                    type={"button"} 
                  >
                    Generate PDF
                  </CustomButton>
                </Box>
              }
                  <CsvUploadComponent CollectibleData={collectionData}/>
                </>
              }
               
              </AccordionDetails>
              
            </Accordion>
            </div>
          </GridItem>
        </GridContainer>
      </FormGroup> }
      <ConfirmModal modalOpen={showConfirmPopup.state} text={showConfirmPopup.message} onModalCancel={onModalCancel} onModalSubmit={showConfirmPopup.confirm}/>
      { showPdfModal && 
          <PDFGenerator
              collectiblesData={formInput} 
              showModal={showPdfModal} 
              handleClose={() => { 
                // onCancel();
                setShowPdfModal(false);}} 
              image={backgroundImage} 
              imageLoading={imageLoading} 
              tokenCount={{ countChecked: countChecked, fromCount : fromTokenCount, toCount: toTokenCount }}
          />
        }
    </>

  );
}