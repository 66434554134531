import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Avatar, Box, Button, Divider, Grid, IconButton, Link, Toolbar, Typography, styled } from '@mui/material';
import { BoxContainer, PassportCard } from '../components/PassportCard/PassportCard';
import { CollectionFormContainer } from './FormContainer.tsx/CollectionFormContainer';
import { SearchTextInput } from '../../../components/TextInput/SearchInput/SearchInput';
import { NETWORKS } from '../../../constants';
import { defaultTheme } from '../../../styles';
import { getDefaultCollection } from '../../../services/collection.service';
import { CatalogTypes } from '../../../enums/catalog.enum';
import CopyIcon from '../../../components/CopyIcon/CopyIcon';
import { ExternalCollectionFormContainer } from './FormContainer.tsx/ExternalFormContainer';
import { useAppSelector } from '../../../hooks';
import profile from '../../../assets/images/icons/profileIcon.png'
import verified from '../../../assets/images/icons/verifiedIcon.png'
import plusIcon from '../../../assets/images/icons/plusIcon.png'
import tour from '../../../assets/svg/guidedTour.svg'
import { CollectionDescription } from './components/CollectionDescription';
import { CollectionDescriptionTemplate } from '../../../CollectionDescConstants';
import { Walkthrough } from '../../../components/walkthrough';
import { Wallet } from 'ethers';

export const FirstColumnContent = styled(Grid)(({ theme }) => ({
  // maxHeight: 'calc(100vh - 15rem)',
  overflow: 'scroll !important',
  maxWidth: "100%",
  display: 'flex',

}));

const SecondColumnContent = styled(Grid)(({ theme }) => ({

  // [theme.breakpoints.down('lg')]: {
  height: '100%',

  // height: 'calc(100vh - 8rem)',

  overflow: 'auto',
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "6px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "400px",
  },
}));

interface Props {
  collectionData: any;
  type: CatalogTypes;
  loading?: boolean;
  setLoading: ({ open, message }: { open: boolean, message: string }) => void;
  setRun?: (item: boolean) => void;
  run?: boolean;
  wallet:Wallet;
}

export const CollectionContainer = ({ type, collectionData, setLoading, loading, setRun, run,wallet }: Props) => {

  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [showActive, setShowActive] = useState(true);
  const [isFormForNewPassport, setIsFormForNewPassport] = useState<boolean>(false);
  const [isCollectibleModalOpen, setIsCollectibleModalOpen] = useState(false);
  const [isInitialDescVisible, setInitialDescVisible] = useState<boolean>(false);
  const [walkthroughStepsIndex, setWalkthroughStepsIndex] = useState<number>(0);
  const [showTicketCreation,setShowTicketCreation] = useState<boolean>(false);

  const userDetails = useAppSelector(state => state.AppUser.UserData);
  const entityDetails = useAppSelector(state=>state.Entity.EntityData);
  const [isTransferCollection, setIsTransferCollection] = useState<boolean>(false);

  const defaultCollection = getDefaultCollection(type);

  const sortedCollections = collectionData
    ?.filter(m => 1 === 1)
    .sort((x, y) => (Number(y.isActive) - Number(x.isActive) || Number(y.index) - Number(x.index)));

  const viewCollection = sortedCollections?.filter(obj => obj.isActive === showActive);

  const [searchText, setSearchText] = useState('');

  const filteredCards: [] = sortedCollections?.filter((card) =>
    card.name.toLowerCase().includes(searchText.toLowerCase()) || card?.address.toLowerCase().includes(searchText.toLowerCase())
  ) ?? [];

  const allowCreation = useMemo(()=>{
    if(entityDetails?.entityOnboarded){
      if(type!==CatalogTypes.EXPASSES && type!==CatalogTypes?.PREMIUM_CHAT){
        return true
      }
        if((sortedCollections.filter(i=>i.isActive).length==0 || (viewCollection.length>0 && viewCollection?.length<2 && showActive)) && type==CatalogTypes?.EXPASSES){
          return true
        } else if(sortedCollections?.filter(i=>i.isActive).length==0 && type==CatalogTypes?.PREMIUM_CHAT) {
            return true
        }  else{
          return false
        }
    }
    return true
  },[entityDetails,showActive,viewCollection,type])

  useMemo(() => {
    setIsTransferCollection(false)
    {
      setInitialDescVisible(true);
    }
    setShowActive(true);
    setSearchText("");
    setIsCollectibleModalOpen(false)
    const updateData = async () => {
      if (viewCollection && viewCollection.length > 0) {

        if (!selectedCard) {
          // setSelectedCard(viewCollection[0]);
        }
        else {
          if (selectedCard.address !== "") {
            const collectible = collectionData?.find(obj => obj?.address?.toLowerCase() === selectedCard?.address?.toLowerCase());
            if (collectible) {
              setSelectedCard(collectible);
            }
            // else setSelectedCard(viewCollection[0]);
          }
          else {
            // setSelectedCard(defaultCollection);
          }
        }
      }
      else if (viewCollection && viewCollection.length === 0) {
        setSelectedCard(null);
      }
    }
    updateData();
  }, [type]);

  const AvatarImage = userDetails?.avatarURI && userDetails?.avatarURI == "" || userDetails.avatarURI == "no-avatar"
    || (userDetails.avatarURI && (userDetails.avatarURI.includes("ipfs://")))
    ? profile : userDetails?.avatarURI;

  const onNewCollection = () => {
    setSelectedCard(defaultCollection);
    setIsCollectibleModalOpen(true);
    if(type==CatalogTypes.EVENT){
      setShowTicketCreation(false);
    }
    setInitialDescVisible(false);
    setIsFormForNewPassport(true)
    
  }

  const allTheLinkedCollectionsInChats = useMemo(() => {
    if (type === CatalogTypes.PREMIUM_CHAT) {
      return [...new Set(collectionData?.map(i => i.linkCollectible[0])
        .filter(i => i != undefined && i != null && i != ""))]
    }
    return []
  }, [collectionData])

  const steps = [
    {
      target: '#walkthrough-new',
      title: "hello",
      disableBeacon: true,
      beaconComponent: null,
      spotlightPadding: 2,
      content: <p className='mt-2'><b>Welcome to LOOT8!</b><p className='mt-1'> Tap the yellow 'Plus' button to create your new Experience Passport!</p></p>
    },
    {
      target: '#walkthrough',
      title: "hello",
      disableBeacon: true,
      spotlightPadding: 2,

      beaconComponent: null,
      content: <p className='mt-1'><b>Name:</b> Please name your ExPass. This is an irreversible decision!
        <p className='mt-1'> <b>Description:</b> Please describe your ExPass or give examples of what unique experiences you will provide your fans!</p><p className='mt-1'> <b>Price:</b> Enter your starting price for your ExPass. With each new user the price will increase! This is an irreversible decision.</p>
      </p>

    },
    {
      target: '#image-walkthrough',
      title: "hello",
      disableBeacon: true,
      spotlightPadding: 2,
      arrowComponent: () => <div>hello</div>,
      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "21.5rem" }}>
        <b>Upload Image</b>
        <p>Upload an image to represent your ExPass. Various file sizes can be uploaded to provide the quality images across the platform</p>
      </p>,
    }
  ]

  const collectibleSteps = [
    {
      target: '#walkthrough-new',
      title: "hello",
      disableBeacon: true,
      spotlightPadding: 2,

      beaconComponent: null,
      content: <p className='mt-1'><b>Lets Get Started!</b> <p className='mt-1'> Tap the yellow 'Plus' button to create your Collectible!</p></p>
    },
    {
      target: '.modal-walkthrough',
      title: "hello",
      disableBeacon: true,
      beaconComponent: null,
      spotlightPadding: 2,

      content: <p className='mt-1'><b>Collectible Details</b>
        <p className='mt-1'><b>Name:</b> Select a name that to call your Collectible. Selecting a name is an irreversible decision!</p>
        <p className='mt-1'> <b>Description:</b> Please describe your Collectible or give examples of what this Collectible will hold.
        </p>
      </p>
    },
    {
      target: '.modal-image-walkthrough',
      title: "hello",
      disableBeacon: true,
      spotlightPadding: 2,

      beaconComponent: null,
      content: <p className='mt-1' style={{ maxWidth: "21.5rem" }}>
        <b>Upload Image</b><p>Upload an image to represent your Collectible. Various file sizes can be uploaded to provide the quality images across the platform !</p></p>,
    },
    {
      target: '.modal-advanced-settings',
      title: "hello",
      disableBeacon: true,
      spotlightPadding: 2,

      beaconComponent: null,
      content: <p className='mt-1'><b>Advanced Settings</b>
        <p className='mt-1'> <b>MarketPlace Tradability:</b> Enabling tradability will allow the collection to be tradable in the market place.Once enabled, market place tradability cannot be disabled again.</p>
        <p className='mt-1'><b>Limited Collectible:</b> Limits the number of collectibles available to the public.</p>
        <p className='mt-1'> <b>GPS:</b> Geolocate your collectible so it is only available to users in a specific area!</p>
      </p>
    },

  ]

  const handleTour = (data: any) => {
    setWalkthroughStepsIndex(data?.index)
    if (type == CatalogTypes?.COLLECTIBLES && data?.index + 1 == 3) {

    }
    if (data?.status == "finished" || data?.status == "skipped") {
      setRun(false)
      setInitialDescVisible(true);
      setWalkthroughStepsIndex(0);
    }
  }

  // useEffect(()=>{
  //     if(showActive && viewCollection?.length){
  //       setSelectedCard(viewCollection[0])
  //     }
  // },[showActive,viewCollection])

  const onSelectCollection = async (collectible) => {
    setInitialDescVisible(false);
    setIsCollectibleModalOpen(false);
    if(type==CatalogTypes.EVENT){
      setShowTicketCreation(false);
    }
    setSelectedCard(collectible);
  }

  const handleWalkthrough = () => {
    setInitialDescVisible(false)
    setSelectedCard(defaultCollection)
    if (type == CatalogTypes?.COLLECTIBLES) {
      setIsCollectibleModalOpen(true)
    }
    setRun(true)
  }

  
  
  return (
    <>

      <Box>
        <Walkthrough
          contentClass={"walkthrough-content"}
          containerClass={"walktrough-tooltip"}
          walkthroughStepsIndex={walkthroughStepsIndex}
          externalCondition={((type == CatalogTypes.EXPASSES || type == CatalogTypes?.COLLECTIBLES) && showActive && viewCollection?.length == 0 && !loading) || run}
          run={run} type={type} steps={type == CatalogTypes?.COLLECTIBLES ? collectibleSteps : steps} handleTour={handleTour}
        />
        <AppBar  sx={{ flexGrow:1,backgroundColor: 'rgba(255, 0, 0, 0)', elevation: 0, boxShadow: 'none' }} position="static">
          <Toolbar sx={{display:'flex',
          // justifyContent:{
          //   xs:'center',
          //   sm:'center',
          // },
          textAlign:{
            xs:'center',
            sm:'center',
            lg:'left',
            md:'left',
            
          },
          flexDirection:{sm:'column',md:'row',lg:'row',xl:'row',xs:'column'}}}>
            <div className="collection-container-title me-4 my-md-1 ">
              <span>{(searchText == "" ? (showActive ? "Active " : "Disabled ") : "") + (type != CatalogTypes.EXPASSES ? `${type}s` : "ExPasses") + `(${viewCollection?.length ?? 0})`}</span>
              {(type == CatalogTypes.EXPASSES) || (type == CatalogTypes?.COLLECTIBLES) ?
                <Button onClick={handleWalkthrough} sx={{ marginTop: {
                  lg:0,sm:1,xs:1,xl:0,md:0}, padding: 0 }} >
                  <Box
                  display={'flex'}>
                    <img src={tour} style={{ height: "25px", width: "25px", marginLeft: "10px" }} />
                    <Typography
                 
                    ml={1} alignItems={'center'} fontFamily={'Poppins-Medium'} color={defaultTheme.IconDark}>Walkthrough</Typography>
                  </Box>
                </Button> : <></>}
            </div>
            <Search sx={{
                marginTop:{
                  lg:0,
                  md:2,
                  sm:2,
                  xs:2,
                  xl:0,
                }            
            }}>
              <SearchTextInput searchText={searchText} setSearchText={setSearchText} placeholder={"Search by Name or Address"} />
            </Search>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: 'center' }}>
              <Link href="#/profile">
                <IconButton
                  size="large"
                  edge="end"
                  sx={{ borderRadius: 'unset', padding: 0, marginLeft: 1 }}
                >
                  <Avatar src={AvatarImage} sx={{ width: 32, height: 32, marginLeft: 1 }} />
                  <span className="user-name">{userDetails?.name}</span>
                  {
                    userDetails?.thirdPartyVerifiedURL?.length > 0 &&
                    <img src={verified} alt="_blank" width="14px" height="14px" />
                  }
                </IconButton>
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid container width={'100%'} >
        <Box width={'100%'}  >
          {isInitialDescVisible ?
            <CollectionDescription {...CollectionDescriptionTemplate[type]} />
            : <></>}
          <FirstColumnContent item className=' py-2'>
            {
              showActive &&
              <BoxContainer id='walkthrough-new' onClick={ !allowCreation ? ()=>{} :onNewCollection}
                sx={{ background: defaultTheme.buttonLightTextColor, border: '2px dashed  #4b4b79',opacity: !allowCreation ? 0.5 : 1  }}
                elevation={2} className='mt-3'
              >
                <div className='w-100 h-100 d-flex justify-content-center align-items-center '>
                  <img src={plusIcon} style={{ height: '2rem', width: '2rem' }} />
                </div>
              </BoxContainer>
            }
            {
              searchText === "" ?
                (viewCollection && viewCollection.length > 0 ? viewCollection?.map(
                  (collection, index) =>
                    <PassportCard isSelected={collection == selectedCard} key={index} index={index} data={collection} type={type} onPress={onSelectCollection} />
                )
                  :
                  <BoxContainer sx={{ background: "transparent",marginLeft:'20px' }} elevation={4} className='mt-3'>
                    <Typography variant="subtitle1" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} textAlign={'center'} >
                      {`No active collections`}
                    </Typography>
                  </BoxContainer>
                )
                :
                (filteredCards &&
                  <>
                    {filteredCards.length > 0 ?
                      (
                        <>
                          {filteredCards?.map((collection, index) => {
                            return (<PassportCard isSelected={collection == selectedCard} key={index} index={index} data={collection} type={type} onPress={onSelectCollection} />);
                          })}
                        </>
                      )
                      :
                      <BoxContainer sx={{ background: "transparent" }} elevation={4} className='m-2'>
                        <Typography variant="subtitle1" color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} textAlign={'center'} >
                          {`No results found`}
                        </Typography>
                      </BoxContainer>
                    }
                  </>
                )
            }


          </FirstColumnContent>
        </Box>
        {(!showActive || (showActive && sortedCollections?.filter(obj => obj.isActive === !showActive).length > 0 && searchText === "")) &&
          <div className="collective-view-link py-2 m-1">
            <Link onClick={() => setShowActive(!showActive)}><span>{"View " + (showActive ? "disabled " : "active ") + type + "s"}</span></Link>
          </div>
        }
        {!isInitialDescVisible ?
          <Divider orientation='horizontal' variant='fullWidth' sx={{ width: '100%', alignSelf: 'center', borderWidth: '1px', marginBlock: '1rem', borderColor: defaultTheme.darkColor }} />
          : <></>}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} padding={0}>
          {selectedCard &&
            <Box>
              {!isInitialDescVisible ?
                // <div style={{ display: 'flex', justifyContent: 'space-between' }} className="collection-container-title m-2 ml-3">
           
                    // {
                      selectedCard?.address !== "" &&
                      <Box sx={{flexDirection: {lg:'row',xl:'row',xs:'column',sm:'column',md:'row'}}} display={'flex'} alignItems={'center'}>
                        <Typography mr={'7px'}  fontFamily={'Poppins-SemiBold'} fontSize={'1rem'}>{selectedCard?.name + " "}</Typography>
                      <Box 
                      maxWidth={"100%"}
                      justifyContent={'center'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Typography
                      sx={{
                        fontFamily: 'Poppins-Medium',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: {
                          xs: '80%', // Full width for extra-small and small screens
                          md: '100%', // Default width for medium and larger screens
                          lg:'100%',
                          sm:'80%',
                          xl:'100%'
                        },
                      }}
                      >
                        <a href={NETWORKS[Number(selectedCard?.chain)]?.blockExplorerUrl + "address/" + selectedCard?.address} target='_blank' rel="noopener noreferrer">{selectedCard?.address}</a>
                 
                      </Typography>
                 
                      <CopyIcon text={selectedCard?.address} />
                      </Box>
                      </Box>
          // }
             
                // </div> 
                : <></>}

              <SecondColumnContent item className='custom-scrollbar px-2'>
                {
                  type === CatalogTypes.EXTERNAL ?

                    <ExternalCollectionFormContainer
                      setIsFormForNewPassport={setIsFormForNewPassport}
                      isFormForNewPassport={isFormForNewPassport}
                      firstCard={viewCollection && viewCollection?.length ? viewCollection[0] : defaultCollection}
                      isCollectibleModalOpen={isCollectibleModalOpen}
                      setIsCollectibleModalOpen={setIsCollectibleModalOpen}
                      collectionData={selectedCard}
                      type={type}
                      setLoading={setLoading}
                      setSelectedCard={setSelectedCard}
                    />
                    : !isInitialDescVisible ?
                      <CollectionFormContainer
                      wallet={wallet}
                      setShowTicketCreation={setShowTicketCreation}
                      showTicketCreation={showTicketCreation}
                        showActive={showActive}
                        defaultCollection={defaultCollection}
                        setIsTransferCollection={setIsTransferCollection}
                        isTransferCollection={isTransferCollection}
                        allTheLinkedCollectionsInChats={allTheLinkedCollectionsInChats}
                        setIsFormForNewPassport={setIsFormForNewPassport}
                        isFormForNewPassport={isFormForNewPassport}
                        firstCard={viewCollection && viewCollection?.length ? viewCollection[0] : defaultCollection}
                        isCollectibleModalOpen={isCollectibleModalOpen}
                        setIsCollectibleModalOpen={setIsCollectibleModalOpen}
                        collectionData={selectedCard}
                        allCollectionData={collectionData}
                        type={type}
                        setLoading={setLoading} setSelectedCard={setSelectedCard} /> : <></>
                }
              </SecondColumnContent >
            </Box>
          }
        </Grid>
      </Grid>
    </>
  );
}

