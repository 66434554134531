import { LogToConsoleError, LogToConsole } from "./Logger";

import { fetchCollectionAdditionalData, fetchCollectionData, fetchCollectionMetaData, fetchUsersData } from "./GraphQLHelper";

const getPagedUserData = async (pageSize, pageNumber) => {

    const response = await fetchUsersData(pageNumber, pageSize);
    try {
        if (response?.records?.length > 0) {
            return response;
        }
        else {
            return null;
        }
    }
    catch (ex) {
        LogToConsoleError("Error occured: getPagedUserData subquery", null, ex.message, ex.stack, null);
    }
}

export const getAllUsersDataFromQuery = async (pageSize: number = 50) => {
    try {

        let allUserData = [];
        let pageNumber = 1;
        let totalPages = 1;
        let totalUsers = 0;

        while(pageNumber <= totalPages)
        {
            let userData = await getPagedUserData(pageSize, pageNumber);
            if (userData) {
                let data = userData.records;
                if (data && data.length > 0) {
                    data.forEach(u => {
                        let user = {
                            id: Number(u.userId),
                            name: u.name,
                            wallet: u.wallet,
                            avatarURI: u.avatarURI,
                            dataURI: u.dataURI
                        }
                        allUserData.push(user);
                    });
                    if(pageSize === 0) //in case page size is not specified in app config and also not provided as function param
                    {
                        pageSize = data.length;
                    }
                    if(pageNumber === 1)
                    {
                        totalUsers = userData.count;
                        totalPages = Math.floor(totalUsers / pageSize) + Number(Boolean(totalUsers  % pageSize)); //add one page if there is non-zero reminder.
                    }
                    pageNumber++;
                }
            }
        }

        return allUserData;
    }
    catch (ex) {
        LogToConsoleError("Error occured: getAllUsersData query", null, ex.message, ex.stack, null);
    }
}
