import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography, outlinedInputClasses, textFieldClasses } from '@mui/material';
import { NETWORKS, NetworkId, isProduction, isStaging } from '../../../constants';
import { defaultTheme } from '../../../styles';

interface Props {
  handleChange: (event: SelectChangeEvent) => void,
  selectedChain: any;
  disabled: boolean;
  required: boolean;
  isWhitelist?: boolean;
  placeholder?: string;
}

export default function NetworkSelect({  disabled = false, handleChange, selectedChain, required, isWhitelist = false, placeholder = "Select a Network" }: Props) {
  
  const networks : NetworkId[] = isProduction ? 
    isWhitelist ?
    [
      NetworkId.ARBITRUM_NOVA,
      NetworkId.POLYGON_MAINNET,
      NetworkId.MAINNET,
      NetworkId.CRONOS_MAINNET,
      NetworkId.ARBITRUM_ONE,

    ] : [
      NetworkId.ARBITRUM_NOVA,
      NetworkId.POLYGON_MAINNET,
      NetworkId.ARBITRUM_ONE
      
    ]
    : isStaging ? 
    isWhitelist ? [
      NetworkId.ARBITRUM_NOVA_FORK,
      NetworkId.CRONOS_TESTNET,
      NetworkId.POLYGON_MUMBAI_TESTNET,

    ] : [
      NetworkId.ARBITRUM_NOVA_FORK,
      NetworkId.ARBITRUM_SEPOLIA,
    ] : 
    isWhitelist ?
    [
      NetworkId.ARBITRUM_SEPOLIA,
      NetworkId.POLYGON_MUMBAI_TESTNET,
      NetworkId.GOERLI,
      NetworkId.CRONOS_TESTNET,
      // NetworkId.ARBITRUMTEST
    ] : [
      NetworkId.ARBITRUM_SEPOLIA,
      NetworkId.POLYGON_MUMBAI_TESTNET,
      NetworkId.ARBITRUM_NOVA_FORK,

      // NetworkId.ARBITRUMTEST
    ]

  return (
    <div className="mt-2">
      <Typography variant="body2"  color={defaultTheme.buttonDarkTextColor} fontFamily={'Poppins-Medium'} >
        {'Network'}
      </Typography>
      <Select
        className="input-select-chain-value"
        value={selectedChain}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        placeholder='Select a Network'
        variant='outlined'
        fullWidth
        size='small'
        sx={{
          mt: 1,
          backgroundColor: defaultTheme.newFieldColor,
          color: defaultTheme.buttonDarkTextColor,
          fontFamily: 'Poppins-Medium',
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: 'none',
          },
          [`& .${outlinedInputClasses.disabled}`]: {
            WebkitTextFillColor: defaultTheme.buttonDarkTextColor,
            cursor: 'not-allowed'
          }
        }}
        renderValue={(value : any) => {
          if (!value) {
            return (
              <Typography variant="body2" color={defaultTheme.placeHolder} fontFamily={'Poppins-Medium'} >
                { placeholder }
              </Typography>
            )
          }
          else
          return NETWORKS[value].chainName; }
          }
        MenuProps={{
          MenuListProps: {
            sx: {
              fontFamily: 'Poppins'
            }
          }
        }}
        displayEmpty
        defaultValue=""
      >
        {
          networks && networks.map( (obj, index) => 
            <MenuItem sx={{ fontFamily: 'Poppins-Medium' }} key={index} value={obj}>{ NETWORKS[obj].chainName }</MenuItem>
          )
        }
      </Select>
    </div>
  );
}
